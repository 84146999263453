import React from 'react';
import styled from 'styled-components';

const TTInputText = (props) => {
  const inputElement = React.useRef(null);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      inputElement.current.blur();
    }
  };

  return (
    <InputText
      key={props.passedKey || props.id}
      active={props.active === 'true'}
      htmlFor={props.id}>
      <span className="interalLabel">{props.label}</span>
      <input
        type="text"
        id={props.id}
        name={props.id}
        {...props}
        active={`${props.active}`}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onBlur}
        ref={inputElement}
        onKeyPress={handleKeyPress}
        placeholder={props.placeholder}
      />
    </InputText>
  );
};
export default TTInputText;

const InputText = styled.label`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  position: relative;
  display: flex;
  font-size: 18px;
  /* height: 140px; */
  display: flex;
  flex-direction: column;

  & input {
    text-align: left;
    padding-left: 4px;

    margin: 0;
    height: 40px;
    font-size: 18px;
  }

  & .interalLabel {
    padding-left: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    position: relative;
    align-self: left;
    font-size: 14px;
    color: #4e4e5d;
  }
`;
