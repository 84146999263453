// LIBRARIES
import React from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';

// // CONTEXT
// import {FirebaseContext} from '../../components/Firebase';

// COMPONENTS
import TTButton from './TTButton';
import TTToggle from './TTToggle';

// STYLES
import theme from '../../styles/theme';

// UTILS
import {/* toCent, */ toEuro} from '../../utils/money';

////////////////////////////////////////////////////////////////

const ItemCard = (props) => {
  // Context
  //
  const isSelected = props.itemKey === props.selectedElements.item;
  // const [isOnTomatoma, setIsOnTomatoma] = React.useState(
  //   !props.hiddenToClients,
  // );
  // const [isOnWebMenu, setIsOnWebMenu] = React.useState(props.inWebMenu);

  const onWebMenuToggleId = `onWebMenu-${props.menuID}-${props.categoryID}-${props.itemKey}`;
  const onTomatomaToggleId = `onTomatoma-${props.menuID}-${props.categoryID}-${props.itemKey}`;

  // const {menus, menuID, categoryID, itemKey} = props;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      testValue: true,
      [onWebMenuToggleId]: props.items[props.itemKey]
        ? props.items[props.itemKey].in_web_menu
        : false,
      [onTomatomaToggleId]: props.items[props.itemKey]
        ? !props.items[props.itemKey].hidden_to_clients
        : false,
    },
    onSubmit: (values) => {
      updatePlaceProperties({
        in_web_menu: values[onWebMenuToggleId],
        hidden_to_clients: values[onTomatomaToggleId],
      });
    },
  });

  ///////
  const selectItem = (index) => {
    props.setSelectedElementsCallback({
      ...props.selectedElements,
      item: index,
    });
  };

  // This method takes a subset of place to update,
  // then merges it into the current place
  // updates the state
  const updatePlaceProperties = async (newValues) => {
    let menus = props.menus;

    menus[props.menuID].categories[props.categoryID].items[
      props.itemKey
    ].in_web_menu = Boolean(newValues.in_web_menu);

    menus[props.menuID].categories[props.categoryID].items[
      props.itemKey
    ].hidden_to_clients = Boolean(!newValues.hidden_to_clients);

    props.updatePlaceMenuCallback({
      menu: menus,
    });
  };

  const handleToggleChange = (e) => {
    formik.handleChange(e);
    formik.handleSubmit();
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <ScCardWrapper onClick={() => selectItem(props.itemKey)}>
      <ScCardBody>
        <ScTitle>{props.title}</ScTitle>
        <ScCaption>{props.caption}</ScCaption>
      </ScCardBody>

      <ScPrice>{toEuro(props.price)} €</ScPrice>

      <ScActions>
        <ScTTButton
          visible={isSelected}
          icon="pencil"
          onClick={props.editCallBack}
        />

        {props.webMenuEnabled ? (
          <TTToggle
            id={onWebMenuToggleId}
            name={onWebMenuToggleId}
            key={onWebMenuToggleId}
            checked={!!formik.values[onWebMenuToggleId]}
            onChange={(e) => {
              handleToggleChange(e);
            }}
            activeColor={'green'}
          />
        ) : null}

        <TTToggle
          id={onTomatomaToggleId}
          name={onTomatomaToggleId}
          key={onTomatomaToggleId}
          checked={!!formik.values[onTomatomaToggleId]}
          onChange={(e) => {
            handleToggleChange(e);
          }}
          activeColor={'green'}
        />
      </ScActions>
    </ScCardWrapper>
  );
};

export default ItemCard;

////////////////////////////////////////////////////////////////

const ScCardWrapper = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 16px 8px;

  text-decoration: none;
  &:hover {
    background-color: ${theme.colors.grays[5]};
    & h2 {
      color: ${theme.colors.primary};
    }
  }
`;

const ScCardBody = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const ScTitle = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: ${theme.colors.ink};
  margin-top: 0;
  margin-bottom: 0.3rem;

  /* @media (max-width: 863px) {
    font-size: 14px;
  } */
`;

const ScCaption = styled.h3`
  color: ${theme.colors.grays[1]};
  font-weight: 400;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
`;
const ScPrice = styled.h4`
  display: block;
  font-size: 16px;
  height: 16px;
  color: #4e4e5d;
  font-weight: 400;
  margin: 0;
  min-width: 3em;
`;

const ScActions = styled.div`
  /* width: 30%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ScTTButton = styled(TTButton)`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  padding: 0;
  margin: 0 1em;
`;
