import React from 'react';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import {FirebaseContext} from '../Firebase';

// Style
import theme from '../../styles/theme';
import space from '../../styles/space';
import typography from '../../styles/typography';
import TTButton from '../UI/TTButton';

import * as ROUTES from '../../constants/routes';

const logoImage = require('../../assets/Logo-Tomatoma.png');

const SignUpPage = (props) => {
  const firebase = React.useContext(FirebaseContext);
  const [formError, setFormError] = React.useState('');

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('É necessario inserire una email valida')
      .required('É necessario inserire una email valida'),
    password: Yup.string().required('É necessario inserire la password'),
    termsAccepted: Yup.bool().required(
      'É necessario accettare i termini d’uso',
    ),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      termsAccepted: '',
    },
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
    // validate: (values) => validateHandler(values),
    validationSchema: validationSchema,
  });

  // const [agree, setAgree] = React.useState(false);

  const onSubmitHandler = (values) => {
    firebase
      .doCreateUserWithEmailAndPassword(values.email, values.password)
      .then((authUser) => {
        props.history.push(ROUTES.ACCOUNT);
      })
      .catch((error) => {
        setFormError({error});
      });
  };

  return (
    <SignUpWrapper>
      <img alt="Tomatoma Logo" src={logoImage} style={{width: '140px'}} />
      <Title>Crea il tuo account</Title>
      <Description>Entra in Tomatoma e realizza il tuo menu</Description>
      <StyleForm onSubmit={formik.handleSubmit}>
        <StyleLabel htmlFor="user">Email</StyleLabel>
        <StyleInput
          tabindex="1"
          name="email"
          type="email"
          placeholder="Indirizzo Email"
          {...formik.getFieldProps('email')} // value, onChange, onFocus and onBlur
        />
        {formik.touched.email && formik.errors.email && (
          <ErrorText>{formik.errors.email}</ErrorText>
        )}
        <StyleLabel htmlFor="password">Password</StyleLabel>
        <StyleInput
          name="password"
          type="password"
          placeholder="Password"
          tabindex="2"
          {...formik.getFieldProps('password')}
        />
        {formik.touched.password && formik.errors.password && (
          <ErrorText>{formik.errors.password}</ErrorText>
        )}
        <AgreementWrapper>
          <AgreementCheckBox
            tabindex="3"
            type="checkbox"
            id="termsAcceptes"
            name="termsAcceptes"
            checked={formik.values.termsAccepted}
            {...formik.getFieldProps('termsAccepted')}
          />
          <AgreementLabel htmlFor="agree">
            Confermi di aver letto e accettato le{' '}
            <a
              href="https://tomatoma.app/terms-and-conditions-managers"
              target="_blank"
              rel="noopener noreferrer">
              {' '}
              condizioni generali del servizio
            </a>{' '}
            e l'informativa sulla{' '}
            <a
              href="https://tomatoma.app/privacy-policy"
              target="_blank"
              rel="noopener noreferrer">
              privacy
            </a>
            .
          </AgreementLabel>
        </AgreementWrapper>
        {formik.touched.termsAccepted && formik.errors.termsAccepted && (
          <ErrorText>{formik.errors.termsAccepted}</ErrorText>
        )}
        <TTButton variant="big" /* disabled={isInvalid} */ type="submit">
          Registrati
        </TTButton>
        {formError && <ErrorText>{formError.message}</ErrorText>}
        <LoginInstead>
          <p>Sei già iscritto? </p>
          <StLink to="/login">Effettua il Login</StLink>
        </LoginInstead>
      </StyleForm>
    </SignUpWrapper>
  );
};

export default SignUpPage;

//////////////////////////////////////////////////////////////////

const SignUpWrapper = styled.div`
  min-width: 320px;
  text-align: center;
  margin: 0 auto;
  padding: 0rem;
  background-color: ${theme.colors.yellow};
  height: 100%;
`;

const Title = styled.h1`
  ${typography('page_title')};
  margin-top: ${space(7)};
  color: ${theme.colors.ink};
  text-align: center;
`;

const Description = styled.p`
  ${typography('t_l')};
  margin-bottom: ${space(6)};
  text-align: center;
  color: ${theme.colors.ink};
  font-size: 1.2em;
`;

const StyleForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 360px;
  margin: 0 auto;
`;

const StyleInput = styled.input`
  ${typography('t_l')};
  border-radius: 8px;
  padding: 16px 8px;
  color: ${theme.colors.gray_4};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.white};
  width: 100%;

  &:focus {
    border: 1px solid ${theme.colors.ink};
    color: ${theme.colors.ink};
    outline: none;
  }
`;
const StyleLabel = styled.label`
  ${typography('form_label')};
  font-weight: ${theme.fontWeights.normal};
  color: ${theme.colors.gray_2};
  display: flex;
  justify-content: left;
  margin: 24px 0 8px 0;
`;
const LoginInstead = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${space(7)};
  justify-content: center;
`;
const StLink = styled(Link)`
  ${typography('t_l')};
  color: ${theme.colors.brand_color_2};
  font-weight: ${theme.fontWeights.medium};
  margin-left: ${space(3)};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.brand_color_2};
    border-bottom: 1px solid ${theme.colors.brand_color_2};
    cursor: pointer;
  }
`;

const AgreementWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 2em;
  margin-bottom: ${space(9)};
`;
const AgreementCheckBox = styled.input`
  width: 20px;
  height: 20px;
`;
const AgreementLabel = styled.label`
  width: 90%;
  text-align: left;
  font-size: 14px;
  & a{
    color:#6233B0;
font-weight:500;
text-decoration:none;
  & a:hover{
    text-decoration: underline;
  }
  }

`;

const ErrorText = styled.div`
  position: relative;
  top: 4px;
  left: 4px;
  ${typography('inputErrorMessage')};
  color: ${theme.colors.error_ink};
  font-weight: ${theme.fontWeights.normal};
`;
