import React from 'react';
import styled from 'styled-components';

const TTToggle = (props) => {
  return (
    <Switch
      key={props.id}
      active={props.checked || true}
      activeColor={props.activeColor}
      htmlFor={props.id}
      disabled={props.disabled || false}>
      <input
        onChange={props.onChange}
        type="checkbox"
        id={props.id}
        name={props.name || props.id}
        checked={!!props.checked}
      />
      <span className="slider round"></span>
      <span className="internalLabel">{props.label}</span>
    </Switch>
  );
};
export default TTToggle;

const Switch = styled.label`
  position: relative;
  display: flex;
  font-size: 18px;

  opacity: ${(props) => (props.disabled ? 0.2 : 1)};

  & .internalLabel {
    padding-left: 48px;
    cursor: pointer;
    position: relative;
    top: -2px;
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    width: 1em;
    height: 1em;
    z-index: 9999;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 50%;
    /* left: 50%; */
    transform: translate(0%, -50%);
    width: 26px;
    height: 16px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16),
        0px 2px 4px rgba(0, 0, 0, 0.15);
    }
  }

  input:checked + .slider {
    background-color: ${(props) => props.activeColor === 'red' && '#d90000'};
    background-color: ${(props) => props.activeColor === 'green' && '#0DB954'};
  }

  input:focus + .slider {
    box-shadow: 0 0 1px ${(props) => props.activeColor === 'red' && '#d90000'};
    box-shadow: 0 0 1px ${(props) => props.activeColor === 'green' && '#0DB954'};
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
  }
`;
