// LIBRARIES
import React, {useState} from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import shortid from 'shortid';
import {themeGet} from '@styled-system/theme-get';
import FileUploader from 'react-firebase-file-uploader';
import slugify from 'slugify';
import * as Yup from 'yup';
import {cloneDeep} from 'lodash/fp';

// CONTEXT
import StoreContext from '../../AppState/StoreContext';
import {FirebaseContext} from '../../Firebase';

// COMPONENTS
import TTInput from '../../UI/TTInput';
import TTInputTextarea from '../../UI/TTInputTextarea';
import FormActions from './FormActions';
// import TTInputNumber from '../../UI/TTInputNumber';
import VariantList from '../../UI/VariantList';
import VariantWithOptionsReadOnly from '../../UI/VariantWithOptionsReadOnly';

// STYLES
import theme from '../../../styles/theme';
import typography from '../../../styles/typography';

// UTILS
import {toCent, toEuro} from '../../../utils/money';

const ItemForm = (props) => {
  const MAX_DESCRIPTION_LENGTH = 500;

  // Context
  const globalStateStore = React.useContext(StoreContext);
  const firebase = React.useContext(FirebaseContext);

  const [variantsInEditingMode, setVariantsInEditingMode] = useState(false);

  const {state} = globalStateStore;
  let place = state.place;
  const menuobj = state.menu;
  const menus = menuobj.menu;

  let {menuID, categoryID, index, formType} = props;

  const [variants, setVariants] = useState(
    cloneDeep(
      menus[menuID]?.categories[categoryID]?.items[index]?.variants ?? [],
    ),
  );

  let items = menus[menuID]?.categories[categoryID]?.items ?? null;

  const inheritedVariantsFromMenu = menus[menuID]
    ? menus[menuID]?.variants ?? []
    : [];
  const inheritedVariantsFromCategory =
    menus[menuID]?.categories[categoryID]?.variants ?? [];

  const item = items && items[index] ? cloneDeep(items[index]) : null;

  const [itemDescriptionLength, setItemDescriptionLength] = useState(
    item?.description?.length ?? 0,
  );

  const handleUploadStart = (filename, task) => {
    props.setUploadingStatus({uploading: true, label: '0%'});
    console.log('Uploading', filename);
  };
  const handleUploadError = (err) => {
    props.setUploadingStatus({uploading: false, label: ''});
    console.log(err);
  };
  const handleUploadSuccess = async (filename) => {
    // Create the new filename while will be created by Image Scale extension
    // Firebase extension will put the scaled size "heightxwidth" in the filename
    const baseName = filename.split('.').slice(0, -1).join('.');
    const scaledFilename = baseName + '_1280x1280.jpeg';

    props.setUploadingStatus({
      uploading: true,
      label: 'Scaling...',
    });

    // Wait 2 seconds before trying to find the file in the storage
    // This is optimistic that it won't take more than 2 sec to scale an image.
    setTimeout(() => {
      firebase
        .storage()
        .ref('menuImages')
        .child(scaledFilename)
        .getDownloadURL()
        .then((downloadURL) => {
          if (downloadURL) {
            formik.setValues({
              ...formik.values,
              image: downloadURL,
            });
            menus[menuID].categories[categoryID].items[index] = {
              ...item,
              image: downloadURL,
            };
            props.updatePlaceMenuCallback({
              menu: menus,
            });
            props.setUploadingStatus({uploading: false, label: ''});
          }
        })
        .catch((err) =>
          props.setUploadingStatus({uploading: false, label: ''}),
        );
    }, 3000);
  };

  const handleProgress = (progress) => {
    props.setUploadingStatus({uploading: true, label: progress + '%'});

    console.log(progress);
  };

  const clearImage = () => {
    formik.setValues({
      ...formik.values,
      image: '',
    });
    menus[menuID].categories[categoryID].items[index] = {...item, image: ''};
    props.updatePlaceMenuCallback({
      menu: menus,
    });
  };

  let initialValues = {
    id: shortid.generate(),
    title: '',
    caption: '',
    price: 0,
    description: '',
    image: '',
    hidden_to_clients: false,
    in_web_menu: false,
    variants: [],
  };

  if (formType === 'EDIT') {
    initialValues = {
      id: item.id,
      title: item?.title ?? '',
      caption: item?.caption ?? '',
      description: item?.description ?? '',
      price: toEuro(item.price),
      image: item?.image ?? '',
      in_web_menu: item.in_web_menu,
      hidden_to_clients: item.hidden_to_clients,
      variants: variants ?? [],
    };
  }

  const validationSchema = Yup.object({
    title: Yup.string().required('É necessario inserire titolo'),
    price: Yup.string().required('É necessario inserire prezzo'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      const newValues = {
        ...formik.values,
        price: toCent(formik.values.price),
        variants: variants,
      };

      // Remove undefined values from newValues object
      // MA : I don't understand why we need this?
      // Object.keys(newValues).forEach(
      //   (key) => newValues[key] === undefined && delete newValues[key],
      // );

      if (formType === 'EDIT') {
        //Update record
        menus[menuID].categories[categoryID].items[index] = {
          ...item,
          ...newValues,
        };
      } else {
        //Add record
        menus[menuID].categories[categoryID].items = [...items, newValues];
      }

      // Flush the editing state object
      props.setCurrentlyEditingCallback({
        type: null,
        index: null,
      });

      // Update the db with the new values
      props.updatePlaceMenuCallback({
        menu: menus,
      });
    },
  });

  // This method is called from the VariantList component when
  // adding or updating the variants for the item
  const updateVariantList = (newvariants) => {
    setVariants(newvariants);
  };

  const handleCurrencyChange = (e) => {
    formik.setFieldValue(e.target.id, Number(e.target.value).toFixed(2));
    formik.handleChange(e);
  };

  const handleCurrencyBlur = (e) => {
    formik.setFieldValue(e.target.id, Number(e.target.value).toFixed(2));
    formik.handleBlur(e); // it's important to call this for formik validation step
  };

  const handleCancelVariantList = () => {
    // Flush the selected elements state object
    setVariants(
      cloneDeep(
        menus[menuID]?.categories[categoryID]?.items[index]?.variants ?? [],
      ),
    );
  };

  const handleSaveVariantList = () => {
    const newValues = {
      ...formik.values,
      price: toCent(formik.values.price),
      variants: variants,
    };

    menus[menuID].categories[categoryID].items[index] = {
      ...item,
      ...newValues,
    };

    props.updatePlaceMenuCallback({
      menu: menus,
    });
  };

  const handleCancel = () => {
    // Flush the selected elements state object
    handleCancelVariantList();
    props.setCurrentlyEditingCallback({type: null, index: null});
  };

  return (
    <FormContainer>
      <Form onSubmit={formik.handleSubmit}>
        <Fields>
          <FieldsColumn>
            <ScTTInput
              required
              htmlFor="productTitle"
              name="title"
              id="productTitle"
              type="text"
              placeholder="Nome prodotto"
              value={formik.values.title}
              onChange={(e) => formik.handleChange(e)}
            />
            {formik.touched.title && formik.errors.title && (
              <ErrorText>{formik.errors.title}</ErrorText>
            )}
            <Price>
              <ScPriceTTInput
                required
                name="price"
                id="productPrice"
                value={formik.values.price}
                pattern="[0-9]+([\.,][0-9]+)?"
                step="0.10"
                placeholder="0.00"
                onChange={handleCurrencyChange}
                onBlur={handleCurrencyBlur}
              />
              {formik.touched.price && formik.errors.price && (
                <ErrorText>{formik.errors.price}</ErrorText>
              )}
            </Price>
          </FieldsColumn>
          <FieldsColumn>
            <TTInput
              type="text"
              htmlFor="productCaption"
              name="caption"
              id="productCaption"
              placeholder="Breve descrizione"
              value={formik.values.caption}
              onChange={(e) => formik.handleChange(e)}
            />
            {formik.touched.caption && formik.errors.caption && (
              <ErrorText>{formik.errors.caption}</ErrorText>
            )}
          </FieldsColumn>
          <FieldsColumn>
            <TTInputTextarea
              htmlFor="productDescription"
              name="description"
              id="productDescription"
              placeholder="Descrizione lungo"
              rows={5}
              value={formik.values.description}
              onChange={(e) => {
                const len = e.target.value.length;
                if (len <= MAX_DESCRIPTION_LENGTH) {
                  setItemDescriptionLength(len);
                  formik.handleChange(e);
                }
              }}
            />
          </FieldsColumn>
          <FieldsColumn>
            <MaxLength>
              {itemDescriptionLength}/{MAX_DESCRIPTION_LENGTH}
            </MaxLength>
          </FieldsColumn>
          <Title>Foto</Title>
          {item ? (
            <FieldsColumn>
              {item.image ? (
                <Image>
                  <img
                    src={item.image}
                    alt={item.image}
                    style={{maxHeight: 100}}
                  />
                  <br />
                  <Delete type="button" onClick={() => clearImage()}>
                    ✕
                  </Delete>
                </Image>
              ) : (
                <FileUploaderStyled
                  accept="image/*"
                  name="productimage"
                  filename={(file) =>
                    slugify(place.name + '_' + item.id).toLowerCase()
                  }
                  storageRef={firebase.storage().ref('menuImages')}
                  onUploadStart={handleUploadStart}
                  onUploadError={handleUploadError}
                  onUploadSuccess={handleUploadSuccess}
                  onProgress={handleProgress}
                />
              )}
            </FieldsColumn>
          ) : (
            <FieldsColumn>
              * L'immagine di un prodotto può essere caricata dopo che il
              prodotto è stato salvato
            </FieldsColumn>
          )}
          {/* {item && item.hasOwnProperty('imported_caption') ? (
            <ImportedCaption>{item.imported_caption}</ImportedCaption>
          ) : null} */}
        </Fields>
        <ScFormContainer>
          <ScFields>
            {inheritedVariantsFromMenu.length ||
            inheritedVariantsFromCategory.length ? (
              <Title>Varianti ereditate</Title>
            ) : null}

            {inheritedVariantsFromMenu.length
              ? inheritedVariantsFromMenu.map((variant) => {
                  if (!variant.active) {
                    return null;
                  }
                  return (
                    <VariantWithOptionsReadOnly
                      key={variant.id}
                      inheritFrom="definite in Menu"
                      variant={variant}
                    />
                  );
                })
              : null}
            {inheritedVariantsFromCategory.length
              ? inheritedVariantsFromCategory.map((variant) => {
                  if (!variant.active) {
                    return null;
                  }
                  return (
                    <VariantWithOptionsReadOnly
                      key={variant.id}
                      inheritFrom="definite in Categoria"
                      variant={variant}
                    />
                  );
                })
              : null}
          </ScFields>
          <ScFields>
            <VariantList
              list={variants}
              setVariantsInEditingMode={setVariantsInEditingMode}
              updateVariantList={updateVariantList}
              handleCancelCallback={handleCancelVariantList}
              handleSaveCallback={handleSaveVariantList}
            />
          </ScFields>
        </ScFormContainer>

        <FormActions
          index={index}
          items={items}
          formType={formType}
          disabled={variantsInEditingMode}
          handleCancelCallback={handleCancel}
          updatePlaceMenuCallback={props.updatePlaceMenuCallback}
          setCurrentlyEditingCallback={props.setCurrentlyEditingCallback}
        />
      </Form>
    </FormContainer>
  );
};

export default ItemForm;
const Image = styled.div`
  position: relative;
  margin-top: 1em;
  border-radius: 4px;
`;
const Delete = styled.button`
  /* border-radius: 16px; */
  padding: 4px 7px;
  font-weight: bold;
  background-color: ${themeGet('colors.ink')};
  color: #fff;
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  border: none;

  &:hover {
    color: ${themeGet('colors.ink')};
    background-color: #fff;
  }
`;

const FormContainer = styled.div`
  opacity: ${(props) => props.isPaused && 0.5};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  width: 100%;
  background-color: ${theme.colors.grays[5]};
`;

const Fields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 8px;
`;

const ScTTInput = styled(TTInput)`
  display: flex;
  margin-right: 16px;
  /* border: 1px solid red; */
`;
const ScPriceTTInput = styled(TTInput)`
  display: flex;
  width: 100%;
  border-color: ${theme.colors.grays[1]};
  justify-content: flex-end;
  /* border: 1px solid purple; */
`;

const FieldsColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 20%;
`;

// const ImportedCaption = styled.div`
//   padding: ${themeGet('space[1]', '4px')};
//   color: ${themeGet('colors.ink')};
//   font-size: ${themeGet('fontSizes[0]', '10px')};
// `;

const Form = styled.form`
  width: 100%;
`;

const FileUploaderStyled = styled(FileUploader)`
  margin-top: 16px;
`;

const Title = styled.h4`
  margin-top: 24px;
`;
const MaxLength = styled.p`
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #bebec3;
`;

const ScFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${theme.colors.gray_6};
  /* border-top: 1px solid ${theme.colors.ink}; */
  opacity: ${(props) => props.paused && 0.5};
  /* align-items: flex-start; */
`;

const ScFields = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  padding: 16px 8px;
`;

// const FileUploadColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;
//   margin-left: 16px;
// `;

const ErrorText = styled.div`
  position: relative;
  top: 4px;
  left: 4px;
  padding-bottom: 4px;
  ${typography('inputErrorMessage')};
  color: ${theme.colors.error_ink};
  font-weight: ${theme.fontWeights.normal};
`;
