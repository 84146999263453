import theme from './theme';

// const context = 16;

const space = (n) => {
  // return theme.space[n] / context + 'rem';
  return theme.space[n] + 'px';
};

export default space;
