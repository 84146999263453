const theme = {
  //theme tokens
  colors: {
    // brand colors
    ink: '#17172B',
    dark_blue: '#20293E',
    grays: ['#221F25', '#4E4E5D', '#737383', '#BEBEC3', '#E2E2E5', '#F9F8F8'],
    primary: '#3DA8AB',
    primary_ink: '#248E91',
    yellow: '#FFEF3A',
    orange: '#F25327',
    crimson: '#EC1B4B',
    purple: '#A8216B',
    blue: '#3DA8AB',
    paper: '#F8F5F5',
    // system colors
    error: '#F12D2D',
    error_ink: '#C70303',
    success: '#14DC94',
    success_ink: '#07AA6F',
    warning: '#FEB120',
    warning_ink: '#F99500',
    info: '#14BBE0',
    info_ink: '#00A2C6',
    //
    white: '#FFFFFF',
    black: '#000000',
    menu: ['#3DA8AB', '#175676', '#A8216B', '#EC1B4B', '#F25327'],
  },
  fonts: {base: 'Roboto'},
  fontSizes: [10, 12, 14, 16, 18, 22, 28, 35, 44, 68],
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 800,
  },
  radii: [4, 16, 28],
  borderWidths: [1, 2, 3],
  space: [
    0, // 0
    4, // 1
    8, // 2
    12, // 3
    16, // 4
    20, // 5
    24, // 6
    32, // 7
    40, // 8
    48, // 9
    56, // 10
    64, // 11
    80, // 12
    96, // 13
    104, // 14
    128, // 15
    168, // 16
    256, // 17
  ],
  sizes: [
    0, // 0
    4, // 1
    8, // 2
    12, // 3
    16, // 4
    20, // 5
    24, // 6
    32, // 7
    40, // 8
    48, // 9
    56, // 10
    64, // 11
    80, // 12
    96, // 13
    104, // 14
    128, // 15
    168, // 16
    256, // 17
  ],

  breakpoints: [480, 1024, 1440],
};

theme.fontSizes.body = theme.fontSizes[4];

export default theme;

////////////////////////////////////////////////////////
// Theme Key      CSS Properties
////////////////////////////////////////////////////////
// space    	    margin, margin-top, margin-right, margin-bottom, margin-left, padding, padding-top, padding-right, padding-bottom, padding-left, grid-gap, grid-column-gap, grid-row-gap
// fontSizes	    font-size
// colors	        color, background-color, border-color
// fonts	        font-family
// fontWeights	  font-weight
// lineHeights	  line-height
// letterSpacings	letter-spacing
// sizes	        width, height, min-width, max-width, min-height, max-height
// borders	      border, border-top, border-right, border-bottom, border-left
// borderWidths	  border-width
// borderStyles	  border-style
// radii	        border-radius
// shadows	      box-shadow, text-shadow
// zIndices	      z-index
// transitions    transition
////////////////////////////////////////////////////////
