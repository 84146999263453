import * as React from 'react';
import theme from '../../../styles/theme';

function GeoPoint(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2 21s7.2-6 7.2-11.405C19.4 5.953 16.176 3 12.2 3S5 5.953 5 9.595C5 14.25 12.2 21 12.2 21zm0-8.4a3 3 0 100-6 3 3 0 000 6z"
        fill={props.fill || theme.colors.grays[1]}
      />
    </svg>
  );
}

export default GeoPoint;
