export const ROOT = '/';
export const SIGN_UP = '/signup';
export const LOG_IN = '/login';
export const ORDERS = '/orders';
export const ORDER_WITH_ID = '/orders/:orderId';
export const EDITOR = '/menu-editor';
export const EDITOR_MENU = '/menu-editor/:menuId';
export const EDITOR_MENU_CATEGORY = '/menu-editor/:menuId/:categoryId/';
export const SETTINGS = '/settings';
export const ACCOUNT = '/account';
export const CONNECT_TO_STRIPE = '/connect-to-stripe';
export const PASSWORD_FORGET = '/password-forget';
export const REPORT = '/report';
