import React from 'react';
import styled from 'styled-components';
import {cloneDeep} from 'lodash/fp';
import theme from '../../styles/theme';
import {toCent, toEuro} from '../../utils/money';

// COMPONENTS
import TTInput from './TTInput';
import TTInputNumber from './TTInputNumber';
import TTButton from './TTButton';

// const {colors} = theme;

const VariantOption = (props) => {
  return (
    <OptionWrapper>
      <ScTTInput
        type="text"
        value={props.option.name}
        onChange={(el) => {
          const updatedOption = cloneDeep(props.option);
          updatedOption.name = el.target.value;
          props.updateOption(updatedOption);
        }}
      />
      {props.index === 0 ? (
        <DefaultPriceWrapper>
          {toEuro(props.option.price_increase)} €
        </DefaultPriceWrapper>
      ) : (
        <PriceWrapper>
          <ScPriceTTInputNumber
            type="number"
            defaultValue={toEuro(props.option.price_increase)}
            pattern="[0-9]+([\.,][0-9]+)?"
            step="0.01"
            onFocus={(el) => props.updateOption(props.option)}
            onBlur={(el) => {
              const updatedOption = cloneDeep(props.option);
              updatedOption.price_increase = toCent(el.target.value);
              props.updateOption(updatedOption);
            }}
          />

          <TTButton
            type="button"
            icon="deleteoption"
            iconFill={theme.colors.grays[2]}
            onClick={() => props.deleteOption(props.option.id)}></TTButton>
        </PriceWrapper>
      )}
    </OptionWrapper>
  );
};

export default VariantOption;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
  font-size: 14px;
  color: #737383;
  /* background-color: red; */
`;

const ScTTInput = styled(TTInput)`
  display: flex;
  width: 70%;
  margin-right: 8px;
  font-size: 12px;

  /* border: 1px solid red; */
`;

const DefaultPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 42px;
  width: 40%;
  font-size: 12px;
  /* border:1px solid red; */
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 40%;

  /* border: 1px solid green; */
`;

// const ScPriceTTInput = styled(TTInput)`
//   display: flex;
//   width: 100%;
//   border-color: ${colors.ink};
//   margin-right: 6px;
//   font-size: 12px;

// `;
const ScPriceTTInputNumber = styled(TTInputNumber)`
  display: flex;
  width: 100%;
  margin-right: 6px;
  font-size: 12px;
  /* border: 1px solid purple;   */
`;
