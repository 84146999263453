import * as React from 'react';
import theme from '../../../styles/theme';

function TrashCan(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.355 5.103h4.129c.281 0 .516.221.516.486v1.743c0 .265-.235.486-.516.486H4.516c-.281 0-.516-.22-.516-.486V5.59c0-.265.235-.486.516-.486h4.2l.117-.75A.44.44 0 019.255 4h5.537a.44.44 0 01.422.353l.14.75zM5.126 8.48l.821 10.152C5.994 19.404 6.721 20 7.59 20h8.821c.869 0 1.572-.596 1.62-1.368l.844-10.152H5.126zm4.082 9.534h-.023a.47.47 0 01-.47-.42l-.469-6.664c-.023-.243.188-.464.446-.464.258-.022.493.177.493.42l.469 6.664c.023.243-.188.442-.446.464zm2.792 0c.258 0 .47-.199.47-.442v-6.664c0-.243-.212-.442-.47-.442s-.47.199-.47.442v6.664c0 .243.212.442.47.442zm3.284-.42a.471.471 0 01-.469.42h-.023a.47.47 0 01-.446-.464l.47-6.665c.023-.242.234-.419.492-.419a.47.47 0 01.446.464l-.47 6.664z"
        fill={props.fill || theme.colors.grays[1]}
      />
    </svg>
  );
}

export default TrashCan;
