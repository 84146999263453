// https://blog.bitsrc.io/exporting-data-to-excel-with-react-6943d7775a92

import React, {useState, useEffect, useCallback, useMemo} from 'react';

import {toEuro} from '../../../utils/money';
import StoreContext from '../../../components/AppState/StoreContext';
import {FirebaseContext} from '../../Firebase';
import styled from 'styled-components';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import {DateTime} from 'luxon';
import XLSX from 'xlsx';
import {saveAs} from 'file-saver';

const chartOptionsTemplate = {
  title: {
    text: '',
  },
  yAxis: {
    title: {
      text: 'Euro',
    },
  },
  xAxis: {
    categories: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
    ],
    title: {
      text: 'Giorno',
      labels: {
        labels: {
          step: 1,
        },
      },
    },
  },
  series: [
    {
      type: 'spline',
      name: 'Entrate',
      data: [],
    },
  ],
};

const Report = (props) => {
  const globalStateStore = React.useContext(StoreContext);
  const {state /*, dispatch*/} = globalStateStore;
  const firebase = React.useContext(FirebaseContext);

  const [report, setReport] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartOptions, setChartOptions] = useState(chartOptionsTemplate);

  const [sums, setSums] = useState({
    amount: 0,
    fee: 0,
  });

  const [yearMonth, setYearMonth] = useState(
    DateTime.local().toFormat('yyyyMM'),
  );

  // Create a list for the select box with all the month of last year
  // and all months this year up until current month
  const yearMonthLookup = useMemo(() => {
    const list = [];
    const lastYear = DateTime.local().toFormat('yyyy') - 1;
    const thisYear = DateTime.local().toFormat('yyyy');

    const currentMonth = DateTime.local().month;

    [...Array(12).keys()].map((m) => {
      let month = m + 1;
      month = month < 10 ? '0' + month : month;

      const date = DateTime.fromFormat(
        lastYear + '' + month + '01',
        'yyyyMMdd',
      ).setLocale('it-IT');

      list.push({
        label:
          date.monthLong.charAt(0).toUpperCase() +
          date.monthLong.slice(1) +
          ' ' +
          date.year,
        value: date.toFormat('yyyyMM'),
      });

      return true;
    });

    [...Array(currentMonth).keys()].map((m) => {
      let month = m + 1;
      month = month < 10 ? '0' + month : month;

      const date = DateTime.fromFormat(
        thisYear + '' + month + '01',
        'yyyyMMdd',
      ).setLocale('it-IT');

      list.push({
        label:
          date.monthLong.charAt(0).toUpperCase() +
          date.monthLong.slice(1) +
          ' ' +
          date.year,
        value: date.toFormat('yyyyMM'),
      });

      return true;
    });

    return list;
  }, []);

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  };

  const downloadXlsx = () => {
    let wb = XLSX.utils.book_new();
    wb.Props = {
      Title: 'Tomatoma report ' + yearMonth,
      Author: 'Tomatoma',
      CreatedDate: new Date(),
    };
    wb.SheetNames.push('Report');

    let rows = [];

    rows.push([
      'id',
      'date',
      'plan',
      'ordernumber',
      'type',
      'delivery_place',
      'payment_type',
      'total',
    ]);

    const orders = report.map((item) => [
      item.order_id,
      DateTime.fromFormat(item.order_datetime, 'yyyy-MM-dd HH:mm').toFormat(
        'dd-MM-yyyy HH:mm',
      ),
      item.place_plan,
      item.order_sequence,
      item.order_type,
      item.order_delivery_place,
      item.chosen_payment_method,
      parseFloat(toEuro(item.order_amount)),
    ]);

    rows.push(...orders);

    let ws = XLSX.utils.aoa_to_sheet(rows);

    wb.Sheets['Report'] = ws;

    let wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});

    saveAs(
      new Blob([s2ab(wbout)], {type: 'application/octet-stream'}),
      'tomatoma-report-' + yearMonth + '.xlsx',
    );
  };

  const loadOrders = useCallback(
    async (place_id, year_month) => {
      setLoading(true);

      const ordersRef = firebase.firestore
        .collection('report')
        .where('order_ym', '==', year_month)
        .where('place_id', '==', place_id)
        .orderBy('order_datetime');

      const snapshot = await ordersRef.get();

      if (snapshot.empty) {
        setLoading(false);
        return [];
      }

      let orders = [];
      let idx = 0;

      let total_amount = 0;
      let total_fee = 0;

      const tmpDateTime = DateTime.fromFormat(
        year_month + '01',
        'yyyyMMdd',
      ).setLocale('it-IT');
      const daysInMonth = tmpDateTime.daysInMonth;

      const monthName = tmpDateTime.monthLong + ' ' + tmpDateTime.year;
      const amounts = Array(daysInMonth + 1).fill(0, 0, daysInMonth);
      const fees = Array(daysInMonth + 1).fill(0, 0, daysInMonth);

      snapshot.forEach((doc) => {
        let item = doc.data();

        const day =
          DateTime.fromFormat(item.order_datetime, 'yyyy-MM-dd HH:mm').day - 1;

        idx += 1;
        orders.push({
          idx: idx,
          ...item,
        });
        total_amount += item.order_amount;
        total_fee += item.order_fee;

        amounts[day] += item.order_amount;
        fees[day] += item.order_fee;
      });

      setReport(orders);

      setSums({
        amount: total_amount,
        fee: total_fee,
      });

      const opts = chartOptions;
      opts.title.text =
        'Entrate ' + monthName.charAt(0).toUpperCase() + monthName.slice(1);
      opts.series[0].data = amounts.map((num) => num / 100);

      setChartOptions(opts);

      setLoading(false);
    },
    [firebase.firestore, chartOptions],
  );

  useEffect(() => {
    loadOrders(state.place.id, yearMonth);
  }, [loadOrders, state.place.id, yearMonth]);

  if (loading) {
    return <h1>Loading...</h1>;
  }

  return (
    <ReportWrapper>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      <SelectWrapper>
        <select
          defaultValue={yearMonth}
          onChange={(ev) => setYearMonth(ev.target.value)}>
          {yearMonthLookup.map((ym) => (
            <option key={ym.value} value={ym.value}>
              {ym.label}
            </option>
          ))}
        </select>
        <input type="button" onClick={() => downloadXlsx()} value="XLSX" />
      </SelectWrapper>
      <Table style={{width: '100%'}}>
        <thead>
          <Row>
            <Tdtitle>&nbsp;</Tdtitle>
            <Tdtitle>Data</Tdtitle>
            <Tdtitle>Piano</Tdtitle>
            <Tdtitle>N.Ordine</Tdtitle>
            <Tdtitle>Tipologia</Tdtitle>
            <Tdtitle>P.Consegna</Tdtitle>
            <Tdtitle>Pagamento</Tdtitle>
            <TdtitleRight>Totale</TdtitleRight>
          </Row>
        </thead>
        <tbody>
          {report.map((item) => {
            return (
              <tr key={item.order_id}>
                <Td>{item.idx}</Td>
                <Td>
                  {DateTime.fromFormat(
                    item.order_datetime,
                    'yyyy-MM-dd HH:mm',
                  ).toFormat('dd-MM-yyyy HH:mm')}
                </Td>
                <Td>{item.place_plan}</Td>
                <Td>{item.order_sequence}</Td>
                <Td>{item.order_type}</Td>
                <Td>{item.order_delivery_place}</Td>
                <Td>{item.chosen_payment_method}</Td>
                <Td style={{textAlign: 'right'}}>
                  {toEuro(item.order_amount)} €
                </Td>
              </tr>
            );
          })}
          <TrResults>
            <TdResults colSpan="7">&nbsp;</TdResults>
            <TdResults style={{textAlign: 'right'}}>
              {toEuro(sums.amount)} €
            </TdResults>
          </TrResults>
        </tbody>
      </Table>
    </ReportWrapper>
  );
};

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  min-width: 320px;
  /* text-align: center; */
  margin: 0 auto;
  margin-top: 1em;
  padding: 0rem;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  min-width: 320px;
  /* text-align: center; */
  margin: 0 auto;
  margin-top: 1em;
  padding: 0rem;
`;

const Table = styled.table`
  padding: 2em 0;
`;

const Row = styled.tr``;
const Tdtitle = styled.td`
  border-bottom: 1px solid #e2e2e5;
  color: #1d1221;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0.5em;
`;
const TdtitleRight = styled.td`
  border-bottom: 1px solid #e2e2e5;
  color: #1d1221;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 0.5em;
  text-align: right;
`;
const Td = styled.td`
  border-bottom: 1px solid #e2e2e5;
  color: #737383;
  font-size: 14px;
  padding: 0.5em 0;
`;
const TrResults = styled.tr``;
const TdResults = styled.td`
  color: #1d1221;
  font-size: 14px;
  font-weight: 500;
  padding: 0.5em 0;
`;
export default Report;
