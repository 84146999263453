export default {
  PRODUCTION_firebaseConfig: {
    apiKey: 'AIzaSyBx15MtX7CQaBswIjALEseFPa9Rg7DRbtE',
    authDomain: 'tomatoma-8476d.firebaseapp.com',
    databaseURL: 'https://tomatoma-8476d.firebaseio.com',
    projectId: 'tomatoma-8476d',
    storageBucket: 'tomatoma-8476d.appspot.com',
    messagingSenderId: '158662474924',
    appId: '1:158662474924:web:e42e3f8562411d36',
  },

  STAGING_FirebaseConfig: {
    apiKey: 'AIzaSyBg5GUu-bts8b8HczCahnNRw8oOxBFN4VA',
    authDomain: 'tomatoma-dev.firebaseapp.com',
    databaseURL: 'https://tomatoma-dev.firebaseio.com',
    projectId: 'tomatoma-dev',
    storageBucket: 'tomatoma-dev.appspot.com',
    messagingSenderId: '1077393410862',
    appId: '1:1077393410862:web:97924af027b825ea',
  },

  DEV_STRIPE_CLIENT_ID: 'ca_HLg4pep3hg4Uln87EYfdJVI9kt6gJXOD',
  DEV_STRIPE_REDIRECT_URI:
    'https://europe-west1-tomatoma-dev.cloudfunctions.net/connectManagerWithToken',
  PROD_STRIPE_CLIENT_ID: 'ca_HLg4QJdeBVYCLux7HWundwNLmLNsLlJ3',
  PROD_STRIPE_REDIRECT_URI:
    'https://europe-west1-tomatoma-8476d.cloudfunctions.net/connectManagerWithToken',
};
