import React from 'react';
import styled from 'styled-components';

// COMPONENTS
import DayTimesItem from './DayTimesItem';

// STULES
import theme from '../../../styles/theme';
import space from '../../../styles/space';
import typography from '../../../styles/typography';

export default function TimeTable(props) {
  //
  //Capitalize first letter of day name
  const writeDay = (day) => {
    const daynames = {
      mon: 'Lunedì',
      tue: 'Martedì',
      wed: 'Mercoledì',
      thu: 'Giovedì',
      fri: 'Venerdì',
      sat: 'Sabato',
      sun: 'Domenica',
    };

    return typeof day !== 'string' ? day : daynames[day];
  };

  //Sort days of the week
  const sortedOpeningHours = (openingHours) => {
    let list = [];
    if (openingHours !== null) {
      const sorter = {
        mon: 1,
        tue: 2,
        wed: 3,
        thu: 4,
        fri: 5,
        sat: 6,
        sun: 7,
      };

      let sortKeys = Object.keys(openingHours);

      sortKeys.sort(function sortByDay(a, b) {
        return sorter[a] - sorter[b];
      });

      sortKeys.forEach((item) => {
        list.push({
          day: item,
          hours: openingHours[item],
        });
      });
    }

    return list;
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <>
      <Title>Orari di Apertura</Title>
      <OpeningHours>
        {sortedOpeningHours(props.place?.opening_hours).map(
          (dayTimetable, key) => (
            <OpeningHoursRow key={key}>
              {/* <OpeningHoursDay> */}
              <DayTimesItem
                key={key}
                dayTimetable={dayTimetable}
                dayNameLabel={writeDay(dayTimetable.day)}
                passedKey={key}
                id={`switch${key}`}
              />
            </OpeningHoursRow>
          ),
        )}
      </OpeningHours>
    </>
  );
}

///////////////////////////////////////////////////////////////////////////////////

const Title = styled.h2`
  ${typography('col_title')};
  margin-bottom: ${space(4)};
  font-weight: 500;
`;

const OpeningHours = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: ${space(8)};
`;

const OpeningHoursRow = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  border-bottom: 1px solid ${theme.colors.grays[4]};
`;

// eslint-disable-next-line
const OpeningHoursLabel = styled.div`
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  margin-left: 46px;
  font-size: 18px;
  color: ${theme.colors.black};
`;
