import React from 'react';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
import {FirebaseContext} from '../../Firebase';
import config from '../../../constants/config';

// Style
import theme from '../../../styles/theme';
import space from '../../../styles/space';
import typography from '../../../styles/typography';
import TTButton from '../../UI/TTButton';

const STRIPE_CONNECT_SETTINGS = {
  client_id: null, // loaded later from env
  redirect_uri: null, // loaded later from env
  stripe_user_business_type: 'company', // Ca be either "company" or "individual"
  // stripe_user_email: null,
  suggested_capabilities: 'card_payments', // Ca be either "transfer" or "card_payments"
  // To prevent CSRF attacks, add the state parameter with the value set to a unique token.
  // Stripe includes this state value in the redirect URL that sends the user back to your site.
  state: undefined, // we set this with the UID later
};

if (process.env.REACT_APP_ENV_VAR === 'STAGING') {
  STRIPE_CONNECT_SETTINGS.client_id = config.DEV_STRIPE_CLIENT_ID;
  STRIPE_CONNECT_SETTINGS.redirect_uri = config.DEV_STRIPE_REDIRECT_URI;
} else if (process.env.REACT_APP_ENV_VAR === 'PRODUCTION') {
  STRIPE_CONNECT_SETTINGS.client_id = config.PROD_STRIPE_CLIENT_ID;
  STRIPE_CONNECT_SETTINGS.redirect_uri = config.PROD_STRIPE_REDIRECT_URI;
} else {
  console.error(
    'Wrong configuration or missing Stripe Connect configuration file',
  );
}

const StripeConnect = (props) => {
  const firebase = React.useContext(FirebaseContext);
  const [user /* , initialising, error */] = useAuthState(firebase.auth);
  const [isStripeConnected, setIsStripeConnected] = React.useState(undefined);

  React.useEffect(() => {
    if (user) {
      firebase.firestore
        .doc(`managers/${user.uid}`)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.data().connected_account === undefined) {
            setIsStripeConnected(false);
          } else {
            setIsStripeConnected(true);
          }
        })
        .catch((err) => {
          // THIS NEVER GETS CALLED !
          console.error('Error. Probably this user id not a manager: ', err);
        });
    }
  }, [firebase.firestore, user]);

  // Set the state parameter for Stripe connect to the user id so we can check for it in the cloud functions
  STRIPE_CONNECT_SETTINGS.state = user.uid;
  // const firebaseUserToken = props.user.getIdToken(true);
  // Generate the Stripe Connect link
  const stripeConnectSetupLink = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${STRIPE_CONNECT_SETTINGS.redirect_uri}&client_id=${STRIPE_CONNECT_SETTINGS.client_id}&stripe_user[business_type]=${STRIPE_CONNECT_SETTINGS.stripe_user_business_type}&suggested_capabilities[]=${STRIPE_CONNECT_SETTINGS.suggested_capabilities}&state=${STRIPE_CONNECT_SETTINGS.state}`;

  if (isStripeConnected) {
    return <Redirect to="/account" />;
  }

  return (
    <SignUpWrapper>
      <Title>Profile</Title>
      <Description>Ora che hai un account Tomatoma, inizia a vendere collegando o creando un account Stripe.</Description>
      <a href={stripeConnectSetupLink}>
        <TTButton variant="secondary">Inizia!</TTButton>
      </a>
      <TTButton variant="text" onClick={firebase.doSignOut}>
        Sign Out
      </TTButton>
    </SignUpWrapper>
  );
};
export default StripeConnect;

//////////////////////////////////////////////////////////////////

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  text-align: center;
  margin: 0 auto;
  margin-top: 2em;
  padding: 0rem;
  /* border: 1px solid red; */

  a {
    width: 100%;
  }
`;

const Title = styled.h1`
  ${typography('page_title')};
  margin-top: ${space(7)};
  color: ${theme.colors.ink};
  text-align: center;
`;

const Description = styled.p`
  ${typography('page_description')};
  margin-bottom: ${space(6)};
  text-align: center;
  color: ${theme.colors.ink};
  font-size: 1.2em;
`;

// const Button = styled.a`
//   /* This renders the buttons above... Edit me! */

//   font-size: ${typography("h_medium")};
//   border: 1px solid ${theme.colors.ink};
//   background-color: ${theme.colors.white};
//   color: ${theme.colors.ink};
//   padding: ${space(2)} 0;
//   margin-top: ${space(9)};
//   width: 70%;
//   border-radius: 32px;
//   text-decoration: none;
//   cursor: pointer;

//   &:hover {
//     background-color: ${theme.colors.ink};
//     color: ${theme.colors.white};
//     cursor: pointer;
//   }
//   &:focus {
//     outline: none;
//   }
//   &:active {
//     background-color: ${theme.colors.ink};
//     color: ${theme.colors.white};
//     cursor: pointer;
//   }
// `;
// const TextButton = styled.button`
//   ${typography("t_l")};
//   color: ${theme.colors.brand_color_2};
//   font-weight: ${theme.fontWeights.medium};
//   margin-top: ${space(6)};
//   line-height: 24px;
//   background: none;
//   border: none;

//   &:hover {
//     color: ${theme.colors.brand_color_2};
//     border-bottom: 1px solid ${theme.colors.brand_color_2};
//     cursor: pointer;
//   }
//   &:focus {
//     outline: none;
//   }
// `;
