// LIBRARIES
import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

// CONTEXT
// import StoreContext from "./AppState/StoreContext";
// import { FirebaseContext } from "./Firebase";

// Style
import theme from '../../styles/theme';
import space from '../../styles/space';
import typography from '../../styles/typography';

import * as ROUTES from '../../constants/routes';
import tomatomaLogo from '../../assets/Logo-Tomatoma.png';
import headerBackground from '../../assets/header_background.svg';

// CONTEXT
import StoreContext from '../AppState/StoreContext';

const Navigation = (props) => {
  const globalStateStore = React.useContext(StoreContext);
  const {state, dispatch} = globalStateStore;

  const resetNewOrderBadge = () => {
    dispatch({
      type: 'SET_NEW_ORDER',
      payload: false,
    });
  };

  return (
    <HeaderWrapper>
      <Header>
        <Logo>
          <NavLink to={ROUTES.ROOT}>
            <img alt="logo" src={tomatomaLogo} style={{width: '120px'}} />
          </NavLink>
        </Logo>
        <NavigationWrapper>
          {state.place ? (
            <>
              <NewOrderDot show={state.newOrder ? 'true' : 'false'} />
              <StNavLink to={ROUTES.ORDERS} onClick={resetNewOrderBadge}>
                Ordini
              </StNavLink>
              <StNavLink to={ROUTES.EDITOR}>Menu</StNavLink>
              <StNavLink to={ROUTES.SETTINGS}>Impostazioni</StNavLink>
              <StNavLink to={ROUTES.REPORT}>Report</StNavLink>
            </>
          ) : null}
          <StNavLink to={ROUTES.ACCOUNT}>Account</StNavLink>
        </NavigationWrapper>
      </Header>
    </HeaderWrapper>
  );
};

export default Navigation;

////////////////////////////////////////////////

const HeaderWrapper = styled.div`
  background-image: url(${headerBackground});
  background-repeat: no-repeat;
  overflow: visible;
  background-position-x: center;
  background-position-y: -1rem;
  height: ${space(16)};
  position: sticky;
  position: -webkit-sticky;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  position: -webkit-sticky;
`;

const Logo = styled.div`
  padding-top: ${space(0)};
  padding-left: ${space(6)};
`;

const NavigationWrapper = styled.nav`
  padding-top: ${space(10)};
  padding-right: ${space(10)};
`;

const NewOrderDot = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: relative;
  left: 1.5em;
  top: 0.5em;
  background-color: red;
  opacity: ${(props) => (props.show === 'true' ? 1 : 0)};
`;

const StNavLink = styled(NavLink)`
  ${typography('nav_link')}
  margin-left: ${space(8)};

  &[aria-current='page'] {
    color: ${theme.colors.primary_ink};
    border-bottom: 2px solid ${theme.colors.primary_ink2};
  }
`;
