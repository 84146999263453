import * as React from 'react';
import theme from '../../../styles/theme';

function Pencil(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.647 5.781c.47.473.47 1.24 0 1.712l-.71.713-3.127-3.139.71-.712a1.202 1.202 0 011.706 0l1.421 1.426zm-10.47 12.22l8.81-8.843-3.126-3.138-8.81 8.843 3.127 3.139zm-.953.948L5.107 15.82c-.567.727-1.388 3.729-1.012 4.106.31.311 3.401-.422 4.129-.977z"
        fill={props.fill || theme.colors.grays[1]}
      />
    </svg>
  );
}

export default Pencil;
