export default {
  CREATED: 'CREATED', // Order has been added Firebase and is being processed
  FULFILLED: 'FULFILLED', // Order charge was fulfilled and delivered
  AUTO_FULFILLED: 'AUTO_FULFILLED', // Order will be automatically fulfilled
  REJECTED: 'REJECTED', // Order was rejected by seller and charge has been cancelled
  PAYMENT_INTENT: 'PAYMENT_INTENT', // Payment intent has been a success after creating order. Ready for capture
  FAILED: 'FAILED', // Order payment has failed
  AUTO_ACCEPTED: 'AUTO_ACCEPTED', // Order will be shortly accepted in the cloud function
  ACCEPTED: 'ACCEPTED', // Order has been accepted and payment captured
};
