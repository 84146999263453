import React from 'react';
import {useFormik} from 'formik';
import styled from 'styled-components';
import theme from '../../../styles/theme';
// import * as Yup from 'yup';

// Context
import StoreContext from '../../AppState/StoreContext';
import {FirebaseContext} from '../../Firebase';

// UI Components
import TTButton from '../../UI/TTButton';
import TTSelect from '../../UI/TTSelect';

////////////////////////////////////////////////////////////////////////////////////////////////

export default function TimeFrame(props) {
  // CONTEXT
  const globalStateStore = React.useContext(StoreContext);
  const {state /* , dispatch */} = globalStateStore;
  const firebase = React.useContext(FirebaseContext);

  const timeFrameIndex = props.frameIndex;

  const formik = useFormik({
    initialValues: {
      open: props.timeFrame.open,
      close: props.timeFrame.close,
    },
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
  });

  const onSubmitHandler = async (values) => {
    let newOpeningHours = state.place.opening_hours;
    newOpeningHours[props.dayKey][timeFrameIndex] = {
      open: parseInt(formik.values.open, 10),
      close: parseInt(formik.values.close, 10),
    };
    console.log(newOpeningHours);

    await firebase.firestore.collection(`places`).doc(state.place.id).update({
      opening_hours: newOpeningHours,
    });
  };

  const getAvailableTimes = (startingTime) => {
    const passedMinutes = startingTime % 100;
    const passedHours = Math.trunc(startingTime / 100);

    const startingMinutes = passedMinutes < 30 ? 30 : 0;
    const startingHour = passedMinutes < 30 ? passedHours : passedHours + 1;

    let times = [];
    let firstIteration = true;

    for (let h = startingHour; h <= 23; h++) {
      for (let m = 0; m < 60; m += 30) {
        if (firstIteration) {
          m = startingMinutes;
          firstIteration = false;
        }
        times.push(
          // h.toString().padStart(2, '0') + ':' + m.toString().padStart(2, '0'),
          h * 100 + m,
        );
      }
    }
    return times;
  };

  const formatTime = (timeAsNumber) => {
    const minutes = (timeAsNumber % 100).toString().padStart(2, '0');
    const hours = Math.trunc(timeAsNumber / 100)
      .toString()
      .padStart(2, '0');
    return `${hours}:${minutes} `;
  };

  const handleChange = (e) => {
    formik.handleChange(e);
    formik.handleBlur(e);
    formik.handleSubmit();
  };

  //Remove hours
  const handleDeleteTimeFrame = () => {
    let newOpeningHours = state.place.opening_hours;
    newOpeningHours[props.dayKey].splice(-1, 1);

    updatePlaceProperties({
      opening_hours: newOpeningHours,
    });
  };

  // This method takes a subset of place to update,
  // then merges it into the current place
  // updates the state
  const updatePlaceProperties = async (newprops) => {
    let newplace = {
      ...state.place,
      ...newprops,
    };

    console.log('Save', newprops);

    await firebase.firestore
      .collection(`places`)
      .doc(state.place.id)
      .update(newplace);
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <StyledTimeFrame>
      <StyledTTSelect
        id={`${props.dayKey}-open`}
        name="open"
        active="true"
        value={formik.values.open}
        onChange={handleChange}
        onBlur={formik.handleBlur}>
        <option
          value={formik.values.open}
          label={formatTime(formik.values.open)}>
          {formatTime(formik.values.open)}
        </option>
        {getAvailableTimes(630).map((time, key) => (
          <option key={key} value={time} label={formatTime(time)}>
            {formatTime(time)}
          </option>
        ))}
      </StyledTTSelect>
      :
      <StyledTTSelect
        id={`${props.dayKey}-close`}
        name="close"
        active="true"
        value={formik.values.close}
        onChange={handleChange}
        onBlur={formik.handleBlur}>
        <option
          value={formik.values.close}
          label={formatTime(formik.values.close)}>
          {formatTime(formik.values.close)}
        </option>
        {getAvailableTimes(props.timeFrame.open).map((time, key) => (
          <option key={key} value={time} label={formatTime(time)}>
            {formatTime(time)}
          </option>
        ))}
      </StyledTTSelect>
      {state.place.opening_hours[props.dayKey].length - 1 ===
        props.frameIndex && props.frameIndex !== 0 ? (
        <ScTTButton
          type="button"
          icon="trashcan"
          iconFill={theme.colors.grays[3]}
          onClick={handleDeleteTimeFrame}
        />
      ) : null}
    </StyledTimeFrame>
  );
}

////////////////////////////////////////////////////////////////////////////////////////////////

const StyledTimeFrame = styled.div`
  display: flex;
  position: relative;
`;

const StyledTTSelect = styled(TTSelect)`
  border: 10px solid transparent;
  border-top: none;
`;

const ScTTButton = styled(TTButton)`
  position: absolute;
  right: -24px;
  &:hover {
    background-color: ${theme.colors.grays[1]};
  }
`;
