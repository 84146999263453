import * as React from 'react';
import theme from '../../../styles/theme';

function Table(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M5.277 20H4.5V9.189h2.25L5.277 20zM18.5 20h-.777L16.25 9.189h2.25V20zM17.967 8.34H5.032a2.938 2.938 0 01-1.917-.715C2.583 7.165 2.099 6.694 2 6h19c-.099.694-.584 1.165-1.115 1.625a2.938 2.938 0 01-1.918.714zM22 5H1V4h21v1z"
        fill={props.fill || theme.colors.grays[1]}
      />
    </svg>
  );
}

export default Table;
