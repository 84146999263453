// LIBRARIES
import React from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';

// COMPONENTS
import TTButton from './TTButton';
import TTToggle from './TTToggle';

// STYLES
import theme from '../../styles/theme';

export const MenuCard = (props) => {
  //
  const isSelected = props.menuKey === props.selectedElements.menu;

  const onWebMenuToggleId = `onWebMenu-${props.menuKey}`;
  const onTomatomaToggleId = `onTomatoma-${props.menuKey}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      [onWebMenuToggleId]: props.menu ? props.menu.in_web_menu : false,
      [onTomatomaToggleId]: props.menu ? !props.menu.hidden_to_clients : false,
    },
    onSubmit: (values) => {
      updatePlaceProperties({
        in_web_menu: values[onWebMenuToggleId],
        hidden_to_clients: values[onTomatomaToggleId],
      });
    },
  });

  // This method takes a subset of place to update,
  // then merges it into the current place
  // updates the state
  const updatePlaceProperties = async (newValues) => {
    let menus = props.menus;

    menus[props.menuKey].in_web_menu = Boolean(newValues.in_web_menu);
    // menus[props.menuKey].in_web_menu = false;

    menus[props.menuKey].hidden_to_clients = Boolean(
      !newValues.hidden_to_clients,
    );

    props.updatePlaceMenuCallback({
      menu: menus,
    });
  };

  const handleToggleChange = (e) => {
    formik.handleChange(e);
    formik.handleSubmit();
  };

  /************************************************
   * Updates the selection on clik on Menu
   ************************************************/
  const selectMenu = (index) => {
    props.setSelectedElementsCallback({
      menu: index,
      category: 0,
      item: 0,
    });
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <CardWrapper onClick={() => selectMenu(props.menuKey)}>
      <CardBody>
        <TitleCard>{props.menu.title}</TitleCard>
        <Subtitle>{props.menu.caption}</Subtitle>
      </CardBody>
      <Actions>
        {isSelected && <TTButton icon="pencil" onClick={props.editCallBack} />}
        {props.webMenuEnabled ? (
          <TTToggle
            id={onWebMenuToggleId}
            name={onWebMenuToggleId}
            checked={formik.values[onWebMenuToggleId]}
            onChange={(e) => {
              handleToggleChange(e);
            }}
            activeColor={'green'}
          />
        ) : null}

        <TTToggle
          id={onTomatomaToggleId}
          name={onTomatomaToggleId}
          checked={formik.values[onTomatomaToggleId]}
          onChange={(e) => {
            handleToggleChange(e);
          }}
          activeColor={'green'}
        />
      </Actions>
    </CardWrapper>
  );
};

export const Card = styled.div``;

////////////////////////////////////////////////////////////////

export const CardWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 8px;
  text-decoration: none;
  &:hover {
    background-color: ${theme.colors.grays[5]};
    & h2 {
      color: ${theme.colors.primary};
    }
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleCard = styled.h2`
  color: ${theme.colors.ink};
  margin-top: 0;
  margin-bottom: 0.3rem;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  /* @media (max-width: 863px) {
    font-size: 14px;
  } */
`;

const Subtitle = styled.h3`
  color: ${theme.colors.grays[1]};
  font-weight: 400;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;

  @media (max-width: 863px) {
    font-size: 12px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
