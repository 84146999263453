import React from 'react';
import styled from 'styled-components';

const TTSelect = (props) => {
  const inputElement = React.useRef(null);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      inputElement.current.blur();
    }
  };

  return (
    <InputText
      key={props.passedKey || props.id}
      active={props.active === 'true'}
      htmlFor={props.id}>
      <span className="interalLabel">{props.label}</span>
      <select
        type="text"
        id={props.id}
        name={props.id}
        {...props}
        active={`${props.active}`}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onBlur}
        ref={inputElement}
        onKeyPress={handleKeyPress}
      />
    </InputText>
  );
};
export default TTSelect;

const InputText = styled.label`
  /* opacity: ${(props) => (props.active ? 1 : 0.3)}; */
  display: flex;
  align-items: center;
  font-size: 18px;
  width: 50%;

  & select {
    text-align: left;
    padding-left: 4px;
    margin: 0;
    height: 32px;
    font-size: 16px;
    /* width: 40%; */
  }

  & .interalLabel {
    cursor: pointer;
    color: #1d1221;
    width: 60%;
    margin-right: 32px;
  }
`;
