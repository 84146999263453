import React, {useContext, useState, useEffect} from 'react';
// import /* Link,  withRouter*/ "react-router-dom";
// import shortid from "shortid";
import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
// import queryString from "query-string";
import {FirebaseContext} from '../../Firebase';
import StoreContext from '../../../components/AppState/StoreContext';
// Style
import theme from '../../../styles/theme';
import space from '../../../styles/space';
import * as ROUTES from '../../../constants/routes';
import typography from '../../../styles/typography';
import TTButton from '../../UI/TTButton';
const Account = (props) => {
  const firebase = useContext(FirebaseContext);
  const globalStateStore = useContext(StoreContext);
  const {state, dispatch} = globalStateStore;
  const [user, initialising, firebaseUserError] = useAuthState(firebase.auth);
  const [isStripeConnected, setIsStripeConnected] = useState(undefined);
  const [stripeConnectDashboardLink, setStripeConnectDashboardLink] = useState(
    null,
  );

  useEffect(() => {
    if (user) {
      firebase.firestore
        .doc(`managers/${user.uid}`)
        .get()
        .then((querySnapshot) => {
          const manager = querySnapshot.data();
          if (manager?.connected_account === undefined) {
            setIsStripeConnected(false);
          } else {
            setIsStripeConnected(true);
          }
        })
        .catch((err) => {
          console.error('Error. Probably this user id not a manager: ', err);
        });
    }
  }, [firebase.firestore, user]);

  useEffect(() => {
    if (isStripeConnected) {
      getStripeLoginLink();
    }
  });

  const logoutOfManager = () => {
    firebase.doSignOut();

    dispatch({
      type: 'LOGOUT',
      payload: null,
    });
  }

  const getStripeLoginLink = async () => {
    if (user) {
      const managerUid = user.uid;
      // firebase.functions.useFunctionsEmulator("http://localhost:5001");
      const generateManagerLink = firebase.functions.httpsCallable(
        'getConnectLink',
      );
      const results = await generateManagerLink({uid: managerUid});
      // console.log(results.data.url);
      setStripeConnectDashboardLink(results.data.url);
    }
  };

  const sendPasswordResetEmail = () => {
    firebase.auth
      .sendPasswordResetEmail(user.email)
      .then(function () {
        console.log('Email di reset inviata correttamente');
      })
      .catch(function (error) {
        console.error(
          "C'è stato un errore nell'invio della email di reset: ",
          error,
        );
      });
  };

  /**
   *  RENDER
   */
  if (initialising || isStripeConnected === undefined) {
    return <div>Loading</div>;
  }

  if (firebaseUserError) {
    return (
      <div>
        Error loading the user <br />:{firebaseUserError}
      </div>
    );
  }

  return (
    <AccountWrapper>
      <Left>
        <Section>
          <Title>Email</Title>
          <p>{user.email}</p>
        </Section>
        {/* TODO: implement change pw -> https://stackoverflow.com/questions/39909964/how-to-change-email-in-firebase-auth */}
        <Section>
          <Title>Password</Title>
          <p>••••••••••</p>
          <TTButton variant="text" onClick={sendPasswordResetEmail}>
            Reset Password
          </TTButton>
        </Section>
        <Section>
          <Title>Account</Title>
          <TTButton variant="secondary" onClick={() => logoutOfManager()}>
            Sign Out
          </TTButton>
        </Section>
      </Left>
      <Right>
        {state.place ? (
          <Section>
            <Title>Stripe Account</Title>

            {isStripeConnected ? (
              <LinkButton href={stripeConnectDashboardLink} target="_blank">
                Visualizza i Payout di Stripe
              </LinkButton>
            ) : (
              <NavLink to={ROUTES.CONNECT_TO_STRIPE}>Connect Stripe</NavLink>
            )}
          </Section>
        ) : (
          <>
            <h2>User is not a manager of any place</h2>
            <h3>Contact help@tomatoma.app for assistance</h3>
          </>
        )}
      </Right>
    </AccountWrapper>
  );
};

export default Account;

//////////////////////////////////////////////////////////////////

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
  min-width: 320px;
  /* text-align: center; */
  margin: 0 auto;
  margin-top: 1em;
  padding: 0rem;
`;

const Left = styled.div``;
const Right = styled.div``;

const Section = styled.div`
  margin-bottom: ${space(9)};
`;
const Title = styled.h2`
  ${typography('h_small')};
  font-weight: 500;
  margin-bottom: ${space(4)};
  color: ${theme.colors.ink};
`;

const LinkButton = styled.a`
  padding: 6px 16px;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.ink};
  border-radius: 20px;
  font-size: ${typography('t_l')};
  color: ${theme.colors.ink};

  &:hover {
    background-color: ${theme.colors.ink};
    border: 1px solid ${theme.colors.ink};
    color: ${theme.colors.white};
  }
  &:focus {
    outline: none;
  }
`;
