import React, {useState} from 'react';
import styled from 'styled-components';
import {FirebaseContext} from '../../Firebase';
import StoreContext from '../../AppState/StoreContext';

// Style
import theme from '../../../styles/theme';
import space from '../../../styles/space';
import typography from '../../../styles/typography';

import OrderStatus from '../../../constants/OrderStatus';
import TTButton from '../../UI/TTButton';
import TTInput from '../../UI/TTInput';

const AcceptForm = (props) => {
  const firebase = React.useContext(FirebaseContext);

  const globalStateStore = React.useContext(StoreContext);
  const {state} = globalStateStore;

  const [message, setMessage] = useState('');

  const [manualSequence, setManualSequence] = useState('');

  const order = props.order;

  const use_tt_numbering = state.place.use_tt_numbering ?? true;

  // const order_type = order.hasOwnProperty('order_type')
  //   ? order.order_type
  //   : 'DELIVERY';

  const cancelOperation = () => {
    props.finalize(false);
    setMessage('');
  };

  const acceptOrder = () => {
    const orderUpdateObj = {
      status: OrderStatus.ACCEPTED,
      status_message: message,
    };

    if (!use_tt_numbering) {
      if (manualSequence.length > 0) {
        orderUpdateObj.order_sequence = manualSequence;
      } else {
        return false;
      }
    }

    firebase.firestore
      .collection('orders')
      .doc(order.id)
      .set(orderUpdateObj, {merge: true})
      .then(function () {
        props.finalize(false);
        setMessage('');
      })
      .catch(function (error) {
        console.error('Error writing document: ', error);
        props.finalize(false);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      }}>
      <Title>Accetta l'ordine</Title>
      <Description>Se vuoi puoi personalizzare il messaggio</Description>

      <Textarea
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        placeholder="Ordine preso in consegna!"
        cols={50}
        rows={8}
        style={{
          marginTop: '16px',
          background: '#FFFFFF',
          boxSizing: 'border-box',
          borderRadius: '4px',
        }}></Textarea>
      <br clear="all" />
      <Bottom>
        {!use_tt_numbering ? (
          <Number>
            <Label>Numero dell'ordine:</Label>
            <TTInput
              type="text"
              placeholder={manualSequence}
              onChange={(e) => setManualSequence(e.target.value.trim())}
            />
          </Number>
        ) : null}

        <ActionRow>
          <TTButton variant="text" onClick={() => cancelOperation()}>
            Annulla
          </TTButton>

          {use_tt_numbering || manualSequence ? (
            <TTButton variant="primary" onClick={() => acceptOrder()}>
              Accetta
            </TTButton>
          ) : (
            <TTButton variant="disabled">Accetta</TTButton>
          )}
        </ActionRow>
      </Bottom>
    </div>
  );
};

export default AcceptForm;

const Title = styled.h1`
  ${typography('page_title')};
  color: ${theme.colors.ink};
`;

const Description = styled.p`
  ${typography('t_l')};
  margin-bottom: ${space(4)};
  color: ${theme.colors.ink};
`;

const Textarea = styled.textarea`
  padding: 16px;
  resize: none;
  font-size: 16px;
  color: #4e4e5d;
  border: 1px solid ${theme.colors.gray_5};

  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.ink};
  }
`;

const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px;
`;

const ActionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  height: 100%;
`;

const Number = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const Label = styled.p`
  margin-bottom: 8px;
  text-align: left;
`;
