import React from 'react';
import {Link} from 'react-router-dom';
import {FirebaseContext} from '../Firebase';

// Style
import theme from '../../styles/theme';
import space from '../../styles/space';
import typography from '../../styles/typography';

// import * as ROUTES from "../constants/routes";
import styled from 'styled-components';

const logoImage = require('../../assets/Logo-Tomatoma.png');

const INITIAL_STATE = {
  email: '',
  error: null,
};

const PasswordForgetPage = () => {
  const [formState, setFormState] = React.useState({...INITIAL_STATE});
  const firebase = React.useContext(FirebaseContext);

  const {email, error} = formState;

  const onSubmit = (event) => {
    firebase.auth
      .sendPasswordResetEmail(formState.email)
      .then(function () {
        console.log('Email di reset inviata correttamente');
      })
      .catch(function (error) {
        console.error(
          "C'è stato un errore nell'invio della email di reset: ",
          error,
        );
        setFormState({
          ...formState,
          error: "L'email non sembra corretta. Per favore ricontrollala",
        });
      });
    event.preventDefault();
  };

  const clearError = () => {
    setFormState({...formState, error: null});
  };

  const onChange = (event) => {
    setFormState({...formState, [event.target.name]: event.target.value});
  };

  const isInvalid = email === '';

  return (
    <SignInWrapper>
      <img alt="Tomatoma Logo" src={logoImage} style={{width: '140px'}} />
      <Title>Resetta Password</Title>
      <Description>
        Inserisce l'indirizzo email con il quale ti sei registrato. <br />
        Riceverai una email con un link per il reset.
      </Description>

      <StyleForm>
        <StyleLabel htmlFor="user">Email</StyleLabel>
        <StyleInput
          autoFocus
          name="email"
          onFocus={clearError}
          onChange={onChange}
          type="text"
          placeholder="Email Address"
          tabindex="1"
        />

        {error && <ErrorText>{error}</ErrorText>}
        <Button disabled={isInvalid} onClick={onSubmit} tabindex="3">
          Invia
        </Button>
        <LoginInstead>
          <p>Sei già iscritto? </p>
          <StLink to="/login">Vai al Login</StLink>
        </LoginInstead>
      </StyleForm>
    </SignInWrapper>
  );
};

export default PasswordForgetPage;

//////////////////////////////////////////////////////////////////

const SignInWrapper = styled.div`
  min-width: 320px;
  text-align: center;
  margin: 0 auto;
  padding: 0rem;
  background-color: ${theme.colors.brand_color_1};
  height: 100%;
`;

const Title = styled.h1`
  ${typography('page_title')};
  margin-top: ${space(7)};
  color: ${theme.colors.ink};
  text-align: center;
`;

const Description = styled.p`
  ${typography('page_description')};
  margin-bottom: ${space(6)};
  text-align: center;
  color: ${theme.colors.ink};
  font-size: 1.2em;
`;

const StyleForm = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 360px;
  margin: 0 auto;
`;
const StyleLabel = styled.label`
  ${typography('form_label')};
  display: flex;
  justify-content: left;
  margin: 24px 0 8px 0;
`;

const StyleInput = styled.input`
  ${typography('t_l')};
  border-radius: 8px;
  padding: 16px 8px;
  color: ${theme.colors.gray_4};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.white};

  &:focus {
    border: 1px solid ${theme.colors.ink};
    color: ${theme.colors.ink};
    outline: none;
  }
`;

const ErrorText = styled.p`
  position: absolute;
  top: 250px;
  ${typography('t_m')};
  color: ${theme.colors.error_ink};

  font-weight: ${theme.fontWeights.medium};
`;

const LoginInstead = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${space(7)};
  justify-content: center;
`;

const StLink = styled(Link)`
  ${typography('t_l')};
  color: ${theme.colors.brand_color_2};
  font-weight: ${theme.fontWeights.medium};
  margin-left: ${space(3)};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.brand_color_2};
    border-bottom: 1px solid ${theme.colors.brand_color_2};
    cursor: pointer;
  }
`;
const Button = styled.button`
  /* This renders the buttons above... Edit me! */
  width: 100%;
  padding: ${space(4)} 0;
  margin-top: ${space(9)};
  background-color: ${theme.colors.gray_1};
  border-radius: 32px;
  font-size: ${typography('h_medium')};
  color: ${theme.colors.brand_color_1};
  border: none;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.ink};
    color: ${theme.colors.white};
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  &:active {
    background-color: ${theme.colors.ink};
    color: ${theme.colors.white};
    cursor: pointer;
  }
`;
