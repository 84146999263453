import React, {useState} from 'react';
import styled from 'styled-components';
import {FirebaseContext} from '../../Firebase';

// Style
import theme from '../../../styles/theme';
import space from '../../../styles/space';
import typography from '../../../styles/typography';

import OrderStatus from '../../../constants/OrderStatus';
import TTButton from '../../UI/TTButton';

const RejectForm = (props) => {
  const firebase = React.useContext(FirebaseContext);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const order = props.order;

  const cancelOperation = () => {
    props.finalize(false);
    setMessage('');
  };

  const rejectOrder = () => {
    if (message.trim() === '') {
      setError("Devi specificare un motivo per il rifiuto dell'ordine.");
      return;
    }

    firebase.firestore
      .collection('orders')
      .doc(order.id)
      .set(
        {
          status: OrderStatus.REJECTED,
          status_message: message,
        },
        {merge: true},
      )
      .then(function () {
        props.finalize(false);
        setMessage('');
      })
      .catch(function (error) {
        console.error('Error writing document: ', error);
        props.finalize(false);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
      }}>
      <Title>Rifiuta l’ordine</Title>
      <Description>
        Fai sapere al tuo cliente il motivo del rifiuto.
      </Description>

      <Textarea
        onChange={(e) => {
          if (error) setError('');
          setMessage(e.target.value);
        }}
        placeholder="Ordine rifiutato perchè:"
        cols={50}
        rows={8}
        style={{
          marginTop: '16px',
          background: '#FFFFFF',
          boxSizing: 'border-box',
          borderRadius: '4px',
        }}></Textarea>
      <br clear="all" />

      {error ? <h3 style={{color: 'red'}}>{error}</h3> : null}

      <ActionRow>
        <TTButton variant="text" onClick={() => cancelOperation()}>
          Annulla
        </TTButton>

        <TTButton variant="primary_red" onClick={() => rejectOrder()}>
          Rifiuta
        </TTButton>
      </ActionRow>
    </div>
  );
};

export default RejectForm;

const Title = styled.h1`
  ${typography('page_title')};
  color: ${theme.colors.error_ink};
`;

const Description = styled.p`
  ${typography('t_l')};
  margin-bottom: ${space(4)};
  color: ${theme.colors.ink};
`;

const Textarea = styled.textarea`
  padding: 16px;
  resize: none;
  font-size: 16px;
  color: #4e4e5d;
  border: 1px solid ${theme.colors.gray_5};

  &:focus {
    outline: none;
    border: 1px solid ${theme.colors.ink};
  }
`;

const ActionRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  height: 100%;
`;
