import * as React from 'react';
import theme from '../../../styles/theme';

function CheckmarkCircle(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.148 2.171c-5.462 0-9.915 4.452-9.915 9.915 0 5.462 4.453 9.914 9.915 9.914 5.462 0 9.914-4.452 9.914-9.915 0-5.462-4.452-9.914-9.914-9.914zM9.945 14.289l7.114-7.16c1.194-1.194 3.03.642 1.836 1.835l-8.078 8.079a1.247 1.247 0 01-1.79 0l-3.672-3.626c-1.194-1.194.642-3.03 1.836-1.836l2.754 2.708z"
        fill={props.fill || theme.colors.grays[1]}
      />
    </svg>
  );
}

export default CheckmarkCircle;
