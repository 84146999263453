// LIBRARIES
import React, {useState} from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import QRCode from 'qrcode.react';

// CONTEXT
import {FirebaseContext} from '../../Firebase';

// UTILITIES
import {toCent, toEuro} from '../../../utils/money';

// COMPONENTS
import TTToggle from '../../UI/TTToggle';
import TTSelect from '../../UI/TTSelect';
import TTInputNumber from '../../UI/TTInputNumber';
import TTInputTextarea from '../../UI/TTInputTextarea';

// STYLE
import space from '../../../styles/space';
import typography from '../../../styles/typography';

///////////////////////////////////////////////////////////////////////////////////

export default function ServiceSettings(props) {
  const DELIVERY_PLACE_DESCR_MAXLEN = 180;

  // Context
  const firebase = React.useContext(FirebaseContext);

  let place = props.place;

  const [descriptionLength, setDescriptionLength] = useState(
    (place?.delivery_place_description ?? '').length,
  );

  const qrLink = React.useRef(null);

  const has_printers =
    place.hasOwnProperty('printers') &&
    Array.isArray(place.printers) &&
    place.printers.length > 0;

  const formik = useFormik({
    initialValues: {
      cashPaymentEnabled: place?.cash_payment_enabled ?? false,
      takeAwayEnabled: place?.take_away_enabled ?? true,
      deliveryEnabled: place?.delivery_enabled ?? false,
      webMenuEnabled: place?.web_menu_enabled ?? false,
      autoAcceptOrder: place?.auto_accept_order ?? false,
      printOnStatus: place?.print_on_status ?? 'ACCEPTED',
      servicePaused: place.service_paused,
      deliveryFeeEnabled: place.delivery_fee_enabled,
      deliveryFee: toEuro(place.delivery_fee),
      deliveryPlaceDescriptionEnabled:
        place?.delivery_place_description_enabled ?? false,
      deliveryPlaceDescription: place?.delivery_place_description ?? '',
      minimumOrderEnabled: place.minimum_order_enabled,
      minimumOrder: toEuro(place.minimum_order),
      ttNumbering: place?.use_tt_numbering ?? true,
    },
    onSubmit: (values) => {
      updatePlaceProperties({
        cash_payment_enabled: values.cashPaymentEnabled,
        take_away_enabled: values.takeAwayEnabled,
        delivery_enabled: values.deliveryEnabled,
        auto_accept_order: values.autoAcceptOrder,
        print_on_status: values.printOnStatus,
        web_menu_enabled: values.webMenuEnabled,
        service_paused: values.servicePaused,
        delivery_fee_enabled: values.deliveryFeeEnabled,
        delivery_fee: toCent(values.deliveryFee),
        delivery_place_description_enabled:
          values.deliveryPlaceDescriptionEnabled,
        delivery_place_description: values.deliveryPlaceDescription.trim(),
        minimum_order_enabled: values.minimumOrderEnabled,
        minimum_order: toCent(values.minimumOrder),
        use_tt_numbering: values.ttNumbering,
      });
    },
  });

  // This method takes a subset of place to update,
  // then merges it into the current place
  // updates the state
  const updatePlaceProperties = async (newprops) => {
    await firebase.firestore
      .collection(`places`)
      .doc(props.place.id)
      .update(newprops);
  };

  const handleToggleChange = (e) => {
    formik.handleChange(e);
    formik.handleSubmit();
  };

  const handleCurrencyChange = (e) => {
    formik.setFieldValue(e.target.id, Number(e.target.value).toFixed(2));
    formik.handleChange(e);
  };

  const handleCurrencyBlur = (e) => {
    formik.setFieldValue(e.target.id, Number(e.target.value).toFixed(2));
    formik.handleBlur(e); // it's important to call this for formik validation step
    formik.handleSubmit();
  };

  const downloadQR = (e) => {
    const qrElementCanvas = document.querySelector('#qrcode');
    qrLink.current.href = qrElementCanvas.toDataURL();
    qrLink.current.download = 'QRCode.png';
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <>
      <Title>Impostazioni del Servizio</Title>
      <ServicePaused>
        <TTToggle
          id="servicePaused"
          checked={formik.values.servicePaused}
          activeColor="red"
          onChange={(e) => {
            handleToggleChange(e);
          }}
          label="Metti in pausa momentanea il tuo locale"
        />
      </ServicePaused>
      <DescriptionOptionLabel>
        Non sarà possibile effettuare gli ordini fino alla riattivazione del
        servizio.
      </DescriptionOptionLabel>
      <ScOptionGroup>
        {has_printers ? (
          <PrintOnStatusOption>
            <TTSelect
              label="Stampa gli ordini"
              id="printOnStatus"
              value={formik.values.printOnStatus}
              onChange={(e) => {
                handleToggleChange(e);
              }}>
              <option value="ACCEPTED">Accettati</option>
              <option value="PAYMENT_INTENT">Tutti</option>
            </TTSelect>
          </PrintOnStatusOption>
        ) : null}

        <AutoAcceptOrderOption>
          <TTToggle
            id="autoAcceptOrder"
            checked={formik.values.autoAcceptOrder}
            activeColor="green"
            onChange={(e) => {
              handleToggleChange(e);
            }}
            label="Accetta gli ordini automaticamente"
          />
        </AutoAcceptOrderOption>
        <DescriptionOptionLabel>
          Verranno accettati tutti gli ordini in entrata
        </DescriptionOptionLabel>

        <CashPaymentOption>
          <TTToggle
            id="cashPaymentEnabled"
            checked={formik.values.cashPaymentEnabled}
            activeColor="green"
            onChange={(e) => {
              handleToggleChange(e);
            }}
            label="Pagamenti fuori dall’app"
            disabled={formik.values.servicePaused}
          />
        </CashPaymentOption>
        <DescriptionOptionLabel>
          Permetti alle persone di pagare alla consegna
        </DescriptionOptionLabel>
        <TtNumbering>
          <TTToggle
            id="ttNumbering"
            checked={formik.values.ttNumbering}
            activeColor="green"
            onChange={(e) => {
              handleToggleChange(e);
            }}
            label="Usa la numerazione ordini di Tomatoma"
          />
        </TtNumbering>
        <DescriptionOptionLabel>
          Dovrai scrivere manualmente il numero d’ordine al momento
          dell’accettazione.
        </DescriptionOptionLabel>

        {/**************  OPZIONI DI CONSEGNA E RITIRO *************/}

        <SectionTitle>Opzioni di consegna e ritiro</SectionTitle>

        <TakeAwayOption>
          <TTToggle
            id="takeAwayEnabled"
            checked={formik.values.takeAwayEnabled}
            activeColor="green"
            onChange={(e) => {
              handleToggleChange(e);
            }}
            label="Ritiro"
            disabled={formik.values.servicePaused}
          />
        </TakeAwayOption>
        <DescriptionOptionLabel>
          Attiva il servizio di ritiro.
        </DescriptionOptionLabel>

        <DeliveryOption>
          <TTToggle
            id="deliveryEnabled"
            checked={formik.values.deliveryEnabled}
            activeColor="green"
            onChange={(e) => {
              handleToggleChange(e);
            }}
            label="Consegna"
            disabled={formik.values.servicePaused}
          />
        </DeliveryOption>
        <DescriptionOptionLabel>
          Attiva il servizio di delivery sul posto.
        </DescriptionOptionLabel>

        <ScOptionGroup>
          <DeliveryCost>
            <TTToggle
              id="deliveryFeeEnabled"
              checked={formik.values.deliveryFeeEnabled}
              activeColor="green"
              onChange={(e) => {
                handleToggleChange(e);
              }}
              label="Costo di Consegna"
              disabled={
                formik.values.servicePaused || !formik.values.deliveryEnabled
              }
            />

            <TTInputNumber
              id="deliveryFee"
              active={formik.values.deliveryFeeEnabled}
              {...formik.getFieldProps('deliveryFee')} // value, onChange, onFocus and onBlur
              value={formik.values.deliveryFee}
              onChange={handleCurrencyChange}
              onBlur={handleCurrencyBlur}
              label="€"
              disabled={
                formik.values.servicePaused || !formik.values.deliveryEnabled
              }
            />
          </DeliveryCost>

          <MinimumOrder>
            <TTToggle
              id="minimumOrderEnabled"
              checked={formik.values.minimumOrderEnabled}
              activeColor="green"
              onChange={(e) => {
                handleToggleChange(e);
              }}
              label="Ordine Minimo"
              disabled={
                formik.values.servicePaused || !formik.values.deliveryEnabled
              }
            />

            <TTInputNumber
              id="minimumOrder"
              active={formik.values.minimumOrderEnabled}
              {...formik.getFieldProps('minimumOrder')} // value, onChange, onFocus and onBlur
              value={formik.values.minimumOrder}
              onChange={handleCurrencyChange}
              onBlur={handleCurrencyBlur}
              label="€"
              disabled={
                formik.values.servicePaused || !formik.values.deliveryEnabled
              }
            />
          </MinimumOrder>

          <DeliveryPlaceDescriptionOptionEnabled>
            <TTToggle
              id="deliveryPlaceDescriptionEnabled"
              checked={formik.values.deliveryPlaceDescriptionEnabled}
              activeColor="green"
              onChange={(e) => {
                handleToggleChange(e);
              }}
              label="Personalizza messagio per il Delivery"
              disabled={!formik.values.deliveryEnabled}
            />
          </DeliveryPlaceDescriptionOptionEnabled>
          <DescriptionOptionLabel>
            Lascia informazioni specifiche ai tuoi clienti su come indicare la
            loro posizione
          </DescriptionOptionLabel>
          <DeliveryPlaceDescriptionOption>
            <ScTTInputTextarea
              id="deliveryPlaceDescription"
              name="deliveryPlaceDescription"
              onBlur={(e) => {
                formik.setFieldValue(
                  e.target.id,
                  e.target.value.substring(0, DELIVERY_PLACE_DESCR_MAXLEN),
                );
                formik.handleBlur(e); // it's important to call this for formik validation step
                formik.handleSubmit();
              }}
              value={formik.values.deliveryPlaceDescription}
              onChange={(e) => {
                const len = e.target.value.length;
                if (len <= DELIVERY_PLACE_DESCR_MAXLEN) {
                  setDescriptionLength(len);
                  formik.handleChange(e);
                }
              }}
              placeholder="Messaggio di default: Inserici il numero che identifia la tua posizione o il punto di ritiro più vicino a te."
              disabled={
                !formik.values.deliveryEnabled ||
                !formik.values.deliveryPlaceDescriptionEnabled
              }
            />
          </DeliveryPlaceDescriptionOption>

          <DeliveryPlaceDescriptionOptionLength>
            {descriptionLength + '/' + DELIVERY_PLACE_DESCR_MAXLEN}
          </DeliveryPlaceDescriptionOptionLength>
        </ScOptionGroup>
      </ScOptionGroup>

      <WebMenu>
        <div>
          <TTToggle
            id="webMenuEnabled"
            checked={formik.values.webMenuEnabled}
            activeColor="green"
            onChange={(e) => {
              handleToggleChange(e);
            }}
            label="Web menu"
          />
          <Description50>
            Crea un menu di sola consultazione per il tuo ristorante. Verrà
            creata una pagina web con il tuo menu, raggiungibile tramite
            scansione del Qrcode generato.
          </Description50>
        </div>
        <QRCodeWrapper active={formik.values.webMenuEnabled}>
          <QRCode
            id="qrcode"
            value={`https://webmenu.tomatoma.app/${place.id}/`}
            size={1280}
            style={{width:48, height:48}}
          />

          <a ref={qrLink} href={'http://google.com'} onClick={downloadQR}>
            Scarica QRCode
          </a>
        </QRCodeWrapper>
      </WebMenu>
    </>
  );
}

///////////////////////////////////////////////////////////////////////////////////

const Title = styled.h2`
  ${typography('col_title')};
  margin-bottom: ${space(4)};
  font-weight: 500;
`;

const SectionTitle = styled.h3`
  font-size: 18px;
  line-height: 24px;
  color: #1d1221;
  font-family: 600;
  margin-left: 48px;
  margin-top: 64px;
`;

const ScOptionGroup = styled.div`
  & div {
    padding-left: 2em;
  }
`;

const DescriptionOptionLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 68px;
  max-width: 600px;
  font-size: 14px;
  color: #737383;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;
const Description50 = styled.div`
  display: flex;
  align-items: center;
  margin-left: 48px;
  max-width: 80%;
  font-size: 14px;
  color: #737383;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const ServicePaused = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const PrintOnStatusOption = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const AutoAcceptOrderOption = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const CashPaymentOption = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const TtNumbering = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const TakeAwayOption = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const DeliveryOption = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const DeliveryCost = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const MinimumOrder = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const DeliveryPlaceDescriptionOptionEnabled = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const DeliveryPlaceDescriptionOption = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const ScTTInputTextarea = styled(TTInputTextarea)`
  margin-left: 46px;

  /* border: 1px solid purple; */
`;

const DeliveryPlaceDescriptionOptionLength = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 4px;
  font-size: 12px;
  color: #737383;
  max-width: 600px;
  margin-left: 12px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const WebMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${space(4)};
  justify-content: space-between;
  padding: ${space(6)} 0 0 0;
  max-width: 600px;

  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 378px) {
    width: 100%;
  }
`;

const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (props.active ? 1 : 0.1)};
  align-items: center;
  font-size: 14px;
  justify-content: center;
  margin-top: 12px;
  & canvas {
    margin-right: 1em;

    margin-bottom: 8px;
  }
`;
