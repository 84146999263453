import * as React from 'react';
import theme from '../../../styles/theme';

function DotsHandle(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM15.5 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8.5 13a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM8.5 19a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
        fill={props.fill || theme.colors.grays[1]}
      />
    </svg>
  );
}

export default DotsHandle;
