import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import App from './App';
import StateProvider from './components/AppState/StateProvider';
import Firebase, {FirebaseContext} from './components/Firebase';
import {ThemeProvider} from 'styled-components';
import theme from './styles/theme';

const firebaseInstance = new Firebase();

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <StateProvider>
      <FirebaseContext.Provider value={firebaseInstance}>
        <App />
      </FirebaseContext.Provider>
    </StateProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
