// LIBRARIES
import React from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';

//COMPONENTS
import TTButton from './TTButton';
import TTToggle from './TTToggle';

// STYLES
import theme from '../../styles/theme';

////////////////////////////////////////////////////////////////

const CategoryCard = (props) => {
  //
  const isSelected = props.categoryKey === props.selectedElements.category;

  const categoryPositionId = `${props.menuID}-${props.categoryKey}`;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      [`categoryOnWebMenu-${categoryPositionId}`]: props.category
        ? props.category.in_web_menu
        : false,
      [`categoryOnTomatoma-${categoryPositionId}`]: props.category
        ? !props.category.hidden_to_clients
        : false,
    },
    onSubmit: (values) => {
      updatePlaceProperties({
        in_web_menu: values[`categoryOnWebMenu-${categoryPositionId}`],
        hidden_to_clients: values[`categoryOnTomatoma-${categoryPositionId}`],
      });
    },
  });

  // This method takes a subset of place to update,
  // then merges it into the current place
  // updates the state
  const updatePlaceProperties = async (newValues) => {
    let menus = props.menus;

    menus[props.menuID].categories[props.categoryKey].in_web_menu = Boolean(
      newValues.in_web_menu,
    );

    menus[props.menuID].categories[
      props.categoryKey
    ].hidden_to_clients = Boolean(!newValues.hidden_to_clients);

    props.updatePlaceMenuCallback({
      menu: menus,
    });
  };

  const handleToggleChange = (e) => {
    formik.handleChange(e);
    formik.handleSubmit();
  };

  /************************************************
   * Updates the selection on clik on category
   ************************************************/
  const selectCategory = (index) => {
    props.setSelectedElementsCallback({
      ...props.selectedElements,
      category: index,
      item: 0,
    });
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <ScCardWrapper onClick={() => selectCategory(props.categoryKey)}>
      <ScCardBody>
        <ScTitleCard>{props.category.title}</ScTitleCard>
      </ScCardBody>
      <ScActions>
        {isSelected && <TTButton icon="pencil" onClick={props.editCallBack} />}
        {props.webMenuEnabled ? (
          <TTToggle
            id={`categoryOnWebMenu-${categoryPositionId}`}
            name={`categoryOnWebMenu-${categoryPositionId}`}
            checked={formik.values[`categoryOnWebMenu-${categoryPositionId}`]}
            onChange={(e) => {
              handleToggleChange(e);
            }}
            activeColor={'green'}
          />
        ) : null}

        <TTToggle
          id={`categoryOnTomatoma-${categoryPositionId}`}
          name={`categoryOnTomatoma-${categoryPositionId}`}
          checked={formik.values[`categoryOnTomatoma-${categoryPositionId}`]}
          onChange={(e) => {
            handleToggleChange(e);
          }}
          activeColor={'green'}
        />
      </ScActions>
    </ScCardWrapper>
  );
};

export default CategoryCard;

////////////////////////////////////////////////////////////////

const ScCardWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  text-decoration: none;
  &:hover {
    background-color: ${theme.colors.grays[5]};
    & h2 {
      color: ${theme.colors.primary};
    }
  }
`;

const ScCardBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ScTitleCard = styled.h2`
  font-weight: 500;
  font-size: 14px;
  color: ${theme.colors.ink};
  margin-top: 0;
  margin-bottom: 0.3rem;

  /* @media (max-width: 863px) {
    font-size: 14px;
  } */
`;

const ScActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
