import styled from 'styled-components';
// Style

const DraggableCardWrapper = styled.div`
  /* width: 70%; */
  ${(props) =>
    props.isDragging &&
    `
    opacity:  0.5;
    border: 1px solid #17172B;
    border-style: dashed;
    background-color: #E2E2E5;
    `}
`;

export default DraggableCardWrapper;
