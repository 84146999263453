// LIBRARIES
import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';
import DroppableArea from './DroppableArea';

// CONTEXT

// COMPONENTS
import StoreContext from '../../AppState/StoreContext';
import ItemForm from './ItemForm';
import ItemCard from '../../UI/ItemCard';
import DraggableHandle from '../../UI/ScDraggableCardWrapper';
import TTIcon from '../../UI/TTIcon';
import TTButton from '../../UI/TTButton';

// STYLES
import space from '../../../styles/space';

////////////////////////////////////////////////////////////////

const ItemsList = (props) => {
  // Context
  const globalStateStore = React.useContext(StoreContext);
  const {state} = globalStateStore;

  // const placeID = state.place.id;
  const menuobj = state.menu;
  const menus = menuobj.menu;
  const webMenuEnabled = state.place.web_menu_enabled;

  const menuID =
    props.selectedElements.menu >= 0 ? props.selectedElements.menu : null;

  let categories = menus[menuID] ? menus[menuID].categories : null;
  let categoryID = props.selectedElements.category;

  let items = null;

  if (
    menus[menuID] &&
    menus[menuID].categories &&
    menus[menuID].categories !== null &&
    menus[menuID].categories[categoryID]
  ) {
    items = menus[menuID].categories[categoryID].items;
  }

  let selected = parseInt(props.selectedElements.item);

  const isInEditMode = props.currentlyEditing.type === 'ITEM' ? true : false;
  const editingIndex = isInEditMode ? props.currentlyEditing.index : null;

  const openEditForm = (itemIndex) => {
    props.setCurrentlyEditingCallback({type: 'ITEM', index: itemIndex});
  };

  const openAddForm = () => {
    let newIndex = items ? items.length + 1 : 1; //Todo: This is probably bugged. shouldn't it be just menus.length?
    props.setCurrentlyEditingCallback({
      type: 'ITEM',
      index: newIndex,
    });
  };

  const onDragEnd = (result) => {
    const {destination, source} = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newItems = Array.from(items);
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);

    const newCategory = [
      ...categories.map((item, key) =>
        categoryID === key ? {...item, items: newItems} : item,
      ),
    ];

    const newMenu = [
      ...menus.map((item, key) =>
        menuID === key ? {...item, categories: newCategory} : item,
      ),
    ];
    // Updated the (menu) category order
    props.updatePlaceMenuCallback({
      menu: newMenu,
    });
    props.setSelectedElementsCallback({
      ...props.selectedElements,
      item: destination.index,
    });
  };

  if (items === null) {
    return null;
  }

  return (
    <>
      <DroppableArea onDragEnd={onDragEnd} droppableId={categoryID.toString()}>
        {items &&
          items.map((item, key) => (
            <Draggable
              draggableId={item.id}
              index={key}
              key={item.id}
              // isDragDisabled={selected !== key}
            >
              {(provided, snapshot) => (
                <DraggableCardWrapper
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                  selected={selected === key ? true : false}>
                  <DraggableHandle
                    {...provided.dragHandleProps}
                    // isHovered={'ontouchstart' in window ? true : false}
                    isDragging={snapshot.isDragging}>
                    <ScDotsHandle>
                      <TTIcon icon="dotshandle" />
                    </ScDotsHandle>
                  </DraggableHandle>
                  <Card>
                    {isInEditMode && key === editingIndex ? (
                      //Editing form
                      <ItemForm
                        index={key}
                        menuID={menuID}
                        categoryID={categoryID}
                        formType="EDIT"
                        setUploadingStatus={props.setUploadingStatus}
                        setCurrentlyEditingCallback={
                          props.setCurrentlyEditingCallback
                        }
                        updatePlaceMenuCallback={props.updatePlaceMenuCallback}
                      />
                    ) : (
                      //Item data preview

                      <ItemCard
                        place={state.place}
                        menus={menus}
                        menuID={menuID}
                        categoryID={categoryID}
                        itemKey={key}
                        selected={selected}
                        //
                        itemId={item.id}
                        items={items}
                        title={item.title}
                        caption={item.caption}
                        price={item.price}
                        hiddenToClients={item.hidden_to_clients}
                        webMenuEnabled={webMenuEnabled}
                        inWebMenu={item.in_web_menu}
                        //
                        editCallBack={() => openEditForm(key)}
                        selectedElements={props.selectedElements}
                        setSelectedElementsCallback={
                          props.setSelectedElementsCallback
                        }
                        updatePlaceMenuCallback={props.updatePlaceMenuCallback}
                      />
                    )}
                  </Card>
                </DraggableCardWrapper>
              )}
            </Draggable>
          ))}
      </DroppableArea>
      {/* Add form */}

      {!isInEditMode && items.length === 0 ? (
        <BlankStateMessage>
          Aggiungi il tuo primo prodotto, cliccando su Aggiungi prodotto
        </BlankStateMessage>
      ) : null}

      {isInEditMode && editingIndex === items.length + 1 ? (
        <ItemForm
          formType="ADD"
          menuID={menuID}
          categoryID={categoryID}
          updatePlaceMenuCallback={props.updatePlaceMenuCallback}
          setCurrentlyEditingCallback={props.setCurrentlyEditingCallback}
        />
      ) : (
        <OpenForm>
          <TTButton icon="plus" variant="text" onClick={openAddForm}>
            Aggiungi prodotto
          </TTButton>
        </OpenForm>
      )}
    </>
  );
};

export default ItemsList;

//////////////////////////////////////////////////////////////

const OpenForm = styled.div`
  padding: ${space(6)} ${space(4)};
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const DraggableCardWrapper = styled.div`
  display: flex;
  background-color: ${(props) =>
    props.selected === true ? '#F9F8F8' : 'inherit'};
  border-left: ${(props) =>
    props.selected === true ? '4px solid #3DA8AB' : 'none'};
`;
const Card = styled.div`
  flex-grow: 1;
`;

const ScDotsHandle = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

const BlankStateMessage = styled.div`
  font-size: 14px;
  line-height: 1.3em;
  color: #4e4e5d;
  padding: 16px;
`;