import currency from 'currency.js';

export const toCent = (amount) => {
  const val = parseFloat(amount.toString().replace(",", "."));

  return parseInt(currency(val).multiply(100).value);
};

export const toEuro = (cents) => {
  return currency(cents, { fromCents: true }).value.toFixed(2);
};
