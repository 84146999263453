import * as React from 'react';
import theme from '../../../styles/theme';

function Umbrella(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 21" fill="none" {...props}>
      <path
        d="M11.624 0c.281 0 .51.24.51.535v.534h-1.019V.535c0-.296.228-.535.51-.535zM12.21 1.16c4.905.692 11.091 4.754 11.123 10.032-.435-.61-2.976-.769-4.073-.769-.805 0-2.225.37-2.739.81-.18-4.383-1.934-8.097-4.311-10.072zM11.038 1.16c-2.377 1.976-4.13 5.69-4.311 10.073-.513-.44-1.934-.81-2.74-.81-1.095 0-3.552.592-3.987 1.203.032-5.278 6.134-9.774 11.038-10.465z"
        fill={props.fill || theme.colors.grays[1]}
      />
      <path
        d="M11.54 1.904c2.106 1.694 3.853 5.146 4.048 9.288-.51-.423-1.23-.769-2.012-.769-.779 0-1.516.376-2.036.81V1.904zM11.54 1.904v9.33c-.52-.435-1.258-.81-2.037-.81-.781 0-1.502.345-2.012.768.196-4.142 1.942-7.594 4.049-9.288zM12.133 12.25v7.414c0 1.323-.302 1.336-.509 1.336-.207 0-.509-.013-.509-1.336V12.25h1.018z"
        fill={props.fill || theme.colors.grays[1]}
      />
    </svg>
  );
}

export default Umbrella;
