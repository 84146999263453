import React from 'react';
import styled from 'styled-components';
import {variant, color, fontSize} from 'styled-system';
import TTIcon from './TTIcon';
import theme from '../../styles/theme';
import space from '../../styles/space';

const {colors} = theme;

const TtButton = (props) => (
  <ScButton {...props}>
    {props.icon && <TTIcon icon={props.icon} fill={props.iconFill} />}
    <span>{props.children}</span>
  </ScButton>
);

const ScButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  text-decoration: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  span {
    padding-left: ${space(2)};
  }

  ${variant({
    variants: {
      disabled: {
        py: 5,
        pl: 4,
        pr: 6,
        borderRadius: 0,
        color: 'white',
        bg: colors.grays[3],
        fontSize: 3,
        height: 7,

        cursor: `${'not-allowed'}`,
      },
      primary: {
        py: 5,
        pl: 4,
        pr: 6,
        borderRadius: 0,
        color: 'white',
        bg: 'primary',
        fontSize: 3,
        height: 7,
        '&:hover': {
          bg: 'primary_ink',
        },
      },
      primary_red: {
        py: 5,
        pl: 4,
        pr: 6,
        borderRadius: 0,
        color: 'white',
        bg: 'error',
        fontSize: 3,
        height: 7,
        '&:hover': {
          bg: 'error_ink',
        },
      },
      secondary: {
        py: 5,
        pl: 5,
        pr: 7,
        height: 7,
        borderRadius: 0,
        borderColor: 'ink',
        borderWidth: 0,
        borderStyle: 'solid',
        color: 'ink',
        bg: 'white',
        fontSize: 3,
        '&:hover': {
          bg: colors.ink,
          color: colors.white,
        },
      },
      primary_small: {
        py: 1,
        pl: 1,
        pr: 3,
        height: 6,
        borderRadius: 0,
        color: 'white',
        bg: 'primary',
        fontSize: 3,
        '&:hover': {
          bg: colors.primary_ink,
        },
      },
      secondary_small: {
        py: 1,
        pl: 1,
        pr: 3,
        height: 6,
        borderRadius: 0,
        color: 'ink',
        bg: 'white',
        borderColor: 'ink',
        borderWidth: 0,
        borderStyle: 'solid',
        fontSize: 3,
        '&:hover': {
          bg: 'ink',
          color: 'white',
        },
      },
      big: {
        py: 3,
        pl: 1,
        pr: 3,
        height: 54,
        borderRadius: 2,
        color: colors.grays[4], //TODO - Solve this with string
        bg: 'dark_blue',
        fontSize: 5,
        '&:hover': {
          bg: 'ink',
        },
      },
      text: {
        py: 1,
        pl: 1,
        pr: 3,
        color: 'ink',
        fontSize: 3,
      },
    },
  })};

  ${color};
  ${space};
  ${fontSize};
`;

export default TtButton;
