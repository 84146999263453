// LIBRARIES
import React, {useState} from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {cloneDeep} from 'lodash/fp';

// CONTEXT
import StoreContext from '../../AppState/StoreContext';

// COMPONENTS
import FormActions from './FormActions';
import TTInput from '../../UI/TTInput';
import VariantList from '../../UI/VariantList';

// STYLES
import theme from '../../../styles/theme';
import typography from '../../../styles/typography';

////////////////////////////////////////////////////////////////
const MenuForm = (props) => {
  // Context
  const globalStateStore = React.useContext(StoreContext);
  const {state} = globalStateStore;

  // States
  const [variantsInEditingMode, setVariantsInEditingMode] = useState(false);

  let {index, formType} = props;

  const menuobj = state.menu;
  let menus = menuobj.menu;

  const [variants, setVariants] = useState(
    cloneDeep(menus[index]?.variants ?? []),
  );

  let initialValues = {
    title: '',
    caption: '',
    hidden_to_clients: false,
    color: theme.colors.menu[menus.length % theme.colors.menu.length],
    categories: [],
    variants: [],
  };

  const menu = menus[index];

  if (formType === 'EDIT') {
    initialValues = {
      title: menu.title,
      caption: menu.caption,
      color: menu.color,
      categories: menu.categories,
      // list of variant objects -> { id, name, active, options[] }
      variants: menu?.variants ?? [],
    };
  }

  const validationSchema = Yup.object({
    title: Yup.string().required('É necessario inserire titolo'),
  });

  // This method is called from the VariantList component when
  // adding or updating the variants for the menu
  const updateVariantList = (newvariants) => {
    setVariants(newvariants);
  };

  // Formik Hook
  const formik = useFormik({
    initialValues,

    validationSchema: validationSchema,

    onSubmit: () => {
      if (formType === 'EDIT') {
        //Update record
        formik.setValues({
          ...formik.values,
          variants: variants,
        });
        menus[index] = {...menu, ...formik.values, variants: variants};
      } else {
        // Add new record
        menus = [...menus, formik.values];
      }

      // Update the editing state object
      props.setCurrentlyEditingCallback({
        type: null,
        index: null,
      });

      // Update the db with the new values
      props.updatePlaceMenuCallback({
        menu: menus,
      });
    },
  });

  const handleCancelVariantList = () => {
    // Flush the selected elements state object
    setVariants(cloneDeep(menus[index]?.variants ?? []));
  };

  const handleSaveVariantList = () => {
    menus[index] = {...menu, ...formik.values, variants: variants};

    props.updatePlaceMenuCallback({
      menu: menus,
    });
  };

  /************************************************
   * Update the state for editing to close the form
   ************************************************/
  const handleCancel = () => {
    // When cancelling, reset the list of variants in the state to
    // the same as in the menu
    setVariants(menus[index]?.variants ?? []);
    props.setCurrentlyEditingCallback({
      type: null,
      index: null,
    });
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <ScFormContainer>
      <ScForm onSubmit={formik.handleSubmit}>
        <ScFields>
          <TTInput
            required
            htmlFor="menuTitle"
            name="title"
            id="menuTitle"
            type="text"
            placeholder="Titolo"
            value={formik.values.title}
            onChange={(e) => formik.handleChange(e)}
            variant="large"
          />
          {formik.touched.title && formik.errors.title && (
            <ErrorText>{formik.errors.title}</ErrorText>
          )}
          <TTInput
            htmlFor="menuCaption"
            name="caption"
            id="menuCaption"
            type="text"
            placeholder="Subtitolo"
            value={formik.values.caption}
            onChange={(e) => formik.handleChange(e)}
            variant="small"
          />
          {formik.touched.caption && formik.errors.caption && (
            <ErrorText>{formik.errors.caption}</ErrorText>
          )}
          <ScTTInputColor
            htmlFor="menuColor"
            name="color"
            id="menuColor"
            type="color"
            value={formik.values.color}
            onChange={(e) => formik.handleChange(e)}
          />
          <VariantList
            list={variants}
            setVariantsInEditingMode={setVariantsInEditingMode}
            updateVariantList={updateVariantList}
            handleCancelCallback={handleCancelVariantList}
            handleSaveCallback={handleSaveVariantList}
          />
        </ScFields>
        <FormActions
          items={menus}
          arrayList={formik.initialValues.categories}
          formType={formType}
          index={index}
          disabled={variantsInEditingMode}
          updatePlaceMenuCallback={props.updatePlaceMenuCallback}
          handleCancelCallback={handleCancel}
          setCurrentlyEditingCallback={props.setCurrentlyEditingCallback}
        />
      </ScForm>
    </ScFormContainer>
  );
};

export default MenuForm;

////////////////////////////////////////////////////////////

const ScFormContainer = styled.div`
  width: 100%;
  background-color: ${theme.colors.gray_6};
  border-top: 1px solid ${theme.colors.ink};
  opacity: ${(props) => props.paused && 0.5};
  display: flex;
  align-items: flex-start;
  width: 100%;
`;
const ScFields = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  padding: 16px 8px;
`;

const ScTTInputColor = styled(TTInput)`
  & input {
    width: 48px;
    height: 48px;
  }
`;

const ScForm = styled.form`
  width: 100%;
  background-color: ${theme.colors.grays[5]};
`;

const ErrorText = styled.div`
  position: relative;
  top: 4px;
  left: 4px;
  padding-bottom: 4px;
  ${typography('inputErrorMessage')};
  color: ${theme.colors.error_ink};
  font-weight: ${theme.fontWeights.normal};
`;
