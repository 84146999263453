import React from 'react';
import Alert from './icons/Alert';
import CheckmarkCircle from './icons/CheckmarkCircle';
import QRCode from './icons/QRCode';
import DotsHandle from './icons/DotsHandle';
import GeoPoint from './icons/GeoPoint';
import TrashCan from './icons/TrashCan';
import DeleteOption from './icons/DeleteOption';
import Pencil from './icons/Pencil';
import Plus from './icons/Plus';
import Table from './icons/Table';
import Umbrella from './icons/Umbrella';
import CheckMark from './icons/CheckMark';

export default function TTIcon(props) {
  let IconComponent;
  switch (props.icon) {
    case 'alert':
      IconComponent = Alert;
      break;
    case 'checkmark':
      IconComponent = CheckMark;
      break;
    case 'checkmarkCircle':
      IconComponent = CheckmarkCircle;
      break;
    case 'qrcode':
      IconComponent = QRCode;
      break;
    case 'dotshandle':
      IconComponent = DotsHandle;
      break;
    case 'geopoint':
      IconComponent = GeoPoint;
      break;
    case 'deleteoption':
      IconComponent = DeleteOption;
      break;
    case 'trashcan':
      IconComponent = TrashCan;
      break;
    case 'pencil':
      IconComponent = Pencil;
      break;
    case 'plus':
      IconComponent = Plus;
      break;
    case 'table':
      IconComponent = Table;
      break;
    case 'umbrella':
      IconComponent = Umbrella;
      break;
    default:
      console.error('Wrong icon name used in Icon component: ', props.icon);
  }

  return (
    <>
      <IconComponent fill={props.fill} {...props} />
    </>
  );
}
