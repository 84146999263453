import React from 'react';
import styled from 'styled-components';

// Context
import StoreContext from '../../AppState/StoreContext';
import {FirebaseContext} from '../../Firebase';

import TimeFrame from './TimeFrame';

import TTToggle from '../../UI/TTToggle';

///////////////////////////////////////////////////////////////////////////////////

export default function DayTimes(props) {
  // CONTEXT
  const globalStateStore = React.useContext(StoreContext);
  const {state /* , dispatch */} = globalStateStore;
  const firebase = React.useContext(FirebaseContext);

  let active_days = state.place.active_days || {
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  };

  const [dayEnabled, setDayEnabled] = React.useState(
    active_days[props.dayTimetable.day],
  );

  /////////////

  const dayTimetable = props.dayTimetable;
  const dayKey = dayTimetable.day;
  const dayTimeFrames = dayTimetable.hours;

  const handleToggle = async () => {
    await firebase.firestore
      .collection(`places`)
      .doc(state.place.id)
      .update({
        [`active_days.${dayKey}`]: !dayEnabled,
      });
    //
    setDayEnabled((prevState) => !prevState);
  };

  const addTimeFrame = async () => {
    let new_opening_hours = state.place.opening_hours;
    new_opening_hours[dayKey].push({
      open: 800,
      close: 2000,
    });

    await firebase.firestore.collection(`places`).doc(state.place.id).update({
      opening_hours: new_opening_hours,
    });
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <>
      <OpeningHoursDay>
        <TTToggle
          active={dayEnabled}
          checked={dayEnabled}
          label={props.dayNameLabel}
          onChange={handleToggle}
          activeColor={'green'}
          passedKey={props.passedKey}
          id={props.id}
        />
      </OpeningHoursDay>
      <OpeningHoursTime>
        {dayTimeFrames.map((timeFrame, index) => (
          <TimeFrame
            key={index}
            frameIndex={index}
            dayKey={dayKey}
            timeFrame={timeFrame}
          />
        ))}
        <StyledButton onClick={addTimeFrame}>Aggiungi</StyledButton>
      </OpeningHoursTime>
    </>
  );
}

///////////////////////////////////////////////////////////////////////////////////

const OpeningHoursDay = styled.div`
  display: flex;
  align-items: center;
`;

const OpeningHoursTime = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

const StyledButton = styled.button`
  border: none;
  background: none;
  font-size: 16px;
  color: #3da8ab;
  cursor: pointer;
`;
