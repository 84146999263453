import * as React from 'react';
import theme from '../../../styles/theme';

function QRCode(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 4H4v16h16V4zM2 2v20h20V2H2z"
        fill={props.fill || theme.colors.grays[1]}
      />
      <path
        fill={props.fill || theme.colors.grays[1]}
        d="M5.53 5.529h3.529v3.529H5.53zM14.941 5.529h3.529v3.529h-3.529zM5.53 14.941h3.529v3.529H5.53z"
      />
    </svg>
  );
}

export default QRCode;
