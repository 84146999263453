import React from 'react';
import {DragDropContext, Droppable} from 'react-beautiful-dnd';
import styled from 'styled-components';
import theme from '../../../styles/theme';

const DraggableCard = (props) => {
  const {onDragEnd, onDragStart} = props;

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId={props.droppableId}>
        {(provided) => (
          <Column ref={provided.innerRef} {...provided.droppableProps}>
            {props.children}
            {provided.placeholder}
          </Column>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const Column = styled.div`
  border-bottom: 1px solid ${theme.colors.gray_5};
`;

export default DraggableCard;
