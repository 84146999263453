import React from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import * as Yup from 'yup';

// Context
import {FirebaseContext} from '../../Firebase';

// Style
import space from '../../../styles/space';
import typography from '../../../styles/typography';

//UI Components
import TTInputText from '../../UI/TTInputText';

export default function PlaceInfo(props) {
  // CONTEXT
  const firebase = React.useContext(FirebaseContext);

  let place = props.place;

  const formik = useFormik({
    initialValues: {
      placeName: place.name,
      placeDescription: place.description,
      placeAddress: place.address,
    },
    validationSchema: Yup.object({
      placeName: Yup.string()
        .max(30, 'Il nome non può essere più lungo di 30 caratteri')
        .required('Campo Obbligatorio'),
      placeDescription: Yup.string().max(
        50,
        'La descrizione non può essere più lunga di 50 caratteri',
      ),
      placeAddress: Yup.string().required('Campo Obbligatorio'),
    }),
    onSubmit: (values) => {
      updatePlaceProperties({
        name: values.placeName,
        description: values.placeDescription,
        address: values.placeAddress,
      });
    },
  });

  const updatePlaceProperties = async (newprops, showid) => {
    await firebase.firestore
      .collection(`places`)
      .doc(props.place.id)
      .update(newprops);
  };

  const handleBlur = (e) => {
    if (e.type === 'blur') {
      formik.handleBlur(e); // it's important to call this for formik validation step
      formik.handleSubmit();
    } else {
      // this is a focus event, not a blur
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Title>Informazioni</Title>
      <GeneralDetails key="1">
        <TTInputText
          id="placeName"
          name="placeName"
          onBlur={handleBlur}
          {...formik.getFieldProps('placeName')}
          label="Nome del locale"
          active="true"
        />
      </GeneralDetails>
      <GeneralDetails key="2">
        <TTInputText
          id="placeDescription"
          name="placeDescription"
          {...formik.getFieldProps('placeDescription')}
          onBlur={handleBlur}
          label="Descrizione"
          active="true"
        />
      </GeneralDetails>
      <GeneralDetails key="3">
        <TTInputText
          id="placeAddress"
          name="placeAddress"
          {...formik.getFieldProps('placeAddress')}
          onBlur={handleBlur}
          label="Indirizzo"
          active="true"
        />
      </GeneralDetails>
      {/* This hidden button is needed to handle enter/submit at single input level for forms with multiple inputs  */}
      <button hidden={true} type="submit">
        Submit
      </button>
    </form>
  );
}

const GeneralDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: ${space(10)};
`;

const Title = styled.h2`
  ${typography('col_title')};
  margin-bottom: ${space(4)};
  font-weight: 500;
`;
