import React from 'react';
import styled from 'styled-components';
// import {variant, space, size, color, border} from 'styled-system';
import {cloneDeep} from 'lodash/fp';
import shortid from 'shortid';
// import theme from '../../styles/theme';
import VariantOption from './VariantOption';

// COMPONENTS
import TTButton from './TTButton';
import TTInput from './TTInput';
// const {colors} = theme;

const Variant = (props) => {
  /**
   * Add a new option to variant with default values
   */
  const addOption = () => {
    props.setEditModeWithId(props.variant.id);
    const id = shortid.generate();
    const variant = cloneDeep(props.variant);

    variant.options.push({
      id: id,
      name: 'Nome Opzione',
      price_increase: 0,
      active: true,
    });

    props.updateVariant(variant);
  };

  /**
   *
   * @param {any} updatedOption new option object
   */
  const updateOption = (updatedOption) => {
    props.setEditModeWithId(props.variant.id);

    // take a copy of the options list
    const optionList = cloneDeep(props.variant.options);
    // Find index of object in list
    const idx = optionList.findIndex((opt) => opt.id === updatedOption.id);

    if (idx !== -1) {
      // If it exists we exhange the object
      optionList.splice(idx, 1, updatedOption);

      // Set the new list of options on the variant
      const variant = props.variant;
      variant.options = optionList;

      // Update variant
      props.updateVariant(variant);
    }
  };

  const deleteOption = (id) => {
    props.setEditModeWithId(props.variant.id);

    // take a copy of the options list
    const optionList = cloneDeep(props.variant.options);
    // Find index of object in list
    const idx = optionList.findIndex((opt) => opt.id === id);

    if (idx !== -1) {
      // Delete option on index
      optionList.splice(idx, 1);
      // Set the new list of options on the variant
      const variant = props.variant;
      variant.options = optionList;

      // Update variant
      props.updateVariant(variant);
    }
  };

  return (
    <>
      <span>
        <TTInput
          type="text"
          value={props.variant.name}
          onChange={(el) => {
            const variant = cloneDeep(props.variant);
            variant.name = el.target.value;
            props.updateVariant(variant);
          }}
        />
      </span>

      {props.variant.options.length
        ? props.variant.options.map((opt, index) => (
            <VariantOption
              key={opt.id}
              option={opt}
              index={index}
              updateOption={updateOption}
              deleteOption={deleteOption}
            />
          ))
        : null}

      <ScTTButton icon="plus" variant="text" type="button" onClick={addOption}>
        Opzione
      </ScTTButton>
    </>
  );
};

export default Variant;

const ScTTButton = styled(TTButton)`
  margin: 16px 0;
`;
