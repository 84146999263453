import React, {/* useRef, */ useContext, useState, useEffect} from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import {FirebaseContext} from '../../Firebase';
import AcceptForm from './AcceptForm';
import RejectForm from './RejectForm';
import orderStatus from '../../../constants/OrderStatus';
import {toEuro} from '../../../utils/money';

// Style
import theme from '../../../styles/theme';
import space from '../../../styles/space';
import typography from '../../../styles/typography';
import TTButton from '../../UI/TTButton';
import TTIcon from '../../UI/TTIcon';

// const IconUmbrellaBlack = require('../../../assets/Icons-UmbrellaBlack.png');

const OrderDetails = (props) => {
  const firebase = useContext(FirebaseContext);

  const [order, setOrder] = useState();
  const [modal, setModal] = useState(null);

  let idFromUrl = null;

  if (props.location) {
    idFromUrl = props.match.params.orderId;
  }

  const resetOrderDetails = (result) => {
    document.querySelector('#root').style.filter = 'blur(0px)';
    setModal(null);

    if (result === true) {
      setOrder(null);
    }
  };

  const openRejectModal = () => {
    window.scrollTo(0, 0);
    document.querySelector('#root').style.filter = 'blur(5px)';
    setModal('reject');
  };

  const openAcceptModal = () => {
    window.scrollTo(0, 0);
    document.querySelector('#root').style.filter = 'blur(5px)';
    setModal('accept');
  };

  const setOrderFulfilled = () => {
    if (order) {
      firebase.firestore
        .collection('orders')
        .doc(order.id)
        .set(
          {
            status: orderStatus.FULFILLED,
          },
          {merge: true},
        )
        .then(function () {})
        .catch(function (error) {
          console.error('Error writing document: ', error);
        });
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.firestore
      .doc(`orders/${idFromUrl}`)
      .onSnapshot((orderSnapshot) => {
        setModal(null);

        let orderobj = orderSnapshot.data();

        // If ordering with older Tomatoma, order_type will be missing.
        if (!orderobj.hasOwnProperty('order_type')) {
          orderobj.order_type = 'DELIVERY';
        }

        // If ordering with older Tomatoma, delivery_place will be called deliveryPlace
        if (!orderobj.hasOwnProperty('delivery_place')) {
          orderobj.delivery_place = orderobj.deliveryPlace;
        }

        setOrder({...orderobj, id: orderSnapshot.id});
      });
    document.querySelector('#root').style.filter = 'blur(0px)';

    return () => {
      unsubscribe();
    };
  }, [firebase.firestore, idFromUrl, props.orderID]);

  // Don't show anything if id has not been passed or order has not been found on DB
  if (!idFromUrl || !order) {
    return null;
  }

  return (
    <>
      <ReactModal
        ariaHideApp={false}
        isOpen={modal !== null}
        shouldCloseOnEsc={true}
        onRequestClose={() => resetOrderDetails(false)}
        contentLabel="Reject Modal"
        style={{
          overlay: {
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.0)',
          },
          content: {
            backgroundColor: '#F9F8F8',
            position: 'absolute',
            margin: 'auto auto',
            width: '600px',
            height: '480px',
            border: '1px solid #737383',
            boxShadow: '0px 4px 70px rgba(78, 78, 93, 0.2)',
            borderRadius: '8px',
          },
        }}>
        {modal === 'accept' ? (
          <AcceptForm order={order} finalize={resetOrderDetails} />
        ) : null}
        {modal === 'reject' ? (
          <RejectForm order={order} finalize={resetOrderDetails} />
        ) : null}
      </ReactModal>
      <Header>
        <Title>Dettaglio ordine</Title>
      </Header>

      <DetailsTable>
        <DetailsHeader>
          <OrderInfo>
            <InfoLeft>
              <OrderNumber>
                Numero Ordine: <Number>{order.order_sequence}</Number>
              </OrderNumber>
              <PaymentTypeWrapper>
                Pagamento:
                <PaymentType>
                  {order.chosen_payment_method === 'cash'
                    ? 'Alla Consegna\n'
                    : null}
                  {order.chosen_payment_method === 'card'
                    ? 'Con Carta\n'
                    : null}
                </PaymentType>
              </PaymentTypeWrapper>
              <OrderTypeWrapper>
                <TTIcon icon="geopoint"></TTIcon>
                <OrderType>
                  {order.order_type === 'DELIVERY'
                    ? order.delivery_place
                    : 'TakeAway'}
                  {/* <OrderId>Ordine: {order.id}</OrderId> */}
                </OrderType>
              </OrderTypeWrapper>
            </InfoLeft>
            <InfoRight>
              {/* <IdOrder>id: {order.id}</IdOrder> */}
              <DataOrder>{order.order_datetime}</DataOrder>
            </InfoRight>
          </OrderInfo>
        </DetailsHeader>

        <DetailsTableHeader>
          <Cell1>Q.</Cell1> <Cell2>Articolo</Cell2> <Cell3>Prezzo</Cell3>
        </DetailsTableHeader>

        {order.line_items.map((item, key) => (
          <DetailsTableRow key={key}>
            <Cell1>{item.quantity}</Cell1>
            {/* The following conditional is just for retro-compatibility with old app version that don't write description in the order record */}
            {item.title ? (
              <Cell2>
                {item.category !== undefined ? (
                  <Pcategory>{item.category}</Pcategory>
                ) : null}
                <Ptitle>{item.title}</Ptitle>

                <Pdescription>{item.description}</Pdescription>
              </Cell2>
            ) : (
              <Cell2>
                {item.category !== undefined ? (
                  <Pcategory>{item.category}</Pcategory>
                ) : null}
                <p>{item.description}</p>
              </Cell2>
            )}
            <Cell3>{item.total_price} €</Cell3>
          </DetailsTableRow>
        ))}

        <DetailsTableFooter>
          <Message>
            {order.status_message ? (
              <>
                Messaggio: <br />
                <p>{order.status_message}</p>
              </>
            ) : null}
          </Message>
          <Count>
            <h3>
              Totale{' '}
              {order.chosen_payment_method === 'cash' ? 'da pagare' : null}
            </h3>{' '}
            <h4>{toEuro(order.amount)} €</h4>
          </Count>
        </DetailsTableFooter>
      </DetailsTable>

      <Footer order={order}>
        <LeftActions>
          {order.status === orderStatus.PAYMENT_INTENT && (
            <LeftButtons>
              <TTButton
                variant="primary_red"
                disabled={modal !== null}
                onClick={() => openRejectModal()}>
                Rifiuta
              </TTButton>
              <TTButton
                variant="primary"
                disabled={modal !== null}
                onClick={() => openAcceptModal()}>
                Accetta
              </TTButton>
            </LeftButtons>
          )}
          <OrderFeedback>
            {order.status === orderStatus.REJECTED && (
              <>
                <Rejected>ORDINE RIFIUTATO</Rejected>
              </>
            )}
            {order.status === orderStatus.ACCEPTED && (
              <>
                <Accepted>ORDINE ACCETTATO</Accepted>
              </>
            )}
            {order.status === orderStatus.FAILED && (
              <>
                <Failed>ORDINE FALLITO</Failed>
              </>
            )}
            {(order.status === orderStatus.FULFILLED ||
              order.status === orderStatus.AUTO_FULFILLED) && (
              <>
                <Accepted>ORDINE CONCLUSO</Accepted>
              </>
            )}
          </OrderFeedback>

          {/* PAYMENT Feedback */}
          <ProcessFeedback>
            {order.status === orderStatus.PAYMENT_INTENT &&
            order.chosen_payment_method === 'card'
              ? 'L’effettivo addebito su carta avverrà solo dopo aver accettato l’ordine.'
              : null}

            {order.status === orderStatus.PAYMENT_INTENT &&
            order.chosen_payment_method === 'cash'
              ? 'Il pagamento verrà effettuato al momento del ritiro.'
              : null}

            {(order.status === orderStatus.ACCEPTED ||
              order.status === orderStatus.AUTO_ACCEPTED) &&
            order.chosen_payment_method === 'cash'
              ? 'Il pagamento verrà effettuato al momento del ritiro. Fai sapere al cliente quando l’ordine è pronto.'
              : null}

            {(order.status === orderStatus.ACCEPTED ||
              order.status === orderStatus.AUTO_ACCEPTED) &&
            order.chosen_payment_method === 'card'
              ? 'Importo addebitato correttamente. Fai sapere al cliente quando l’ordine è pronto.'
              : null}

            {order.status === orderStatus.REJECTED
              ? 'Ordine rifiutato con successo. Nessun importo è stato addebitato'
              : null}

            {order.status === orderStatus.FULFILLED ||
            order.status === orderStatus.AUTO_FULFILLED
              ? 'Ordine concluso con successo.'
              : null}
          </ProcessFeedback>
        </LeftActions>

        <RightActions>
          {order.status === orderStatus.PAYMENT_INTENT && (
            <TTButton variant="disabled" disabled={true}>
              Ordine Pronto
            </TTButton>
          )}

          {(order.status === orderStatus.ACCEPTED ||
            order.status === orderStatus.AUTO_ACCEPTED) && (
            <TTButton variant="primary" onClick={() => setOrderFulfilled()}>
              Ordine Pronto
            </TTButton>
          )}
        </RightActions>
      </Footer>
    </>
  );
};

export default OrderDetails;

//////////////////////////////////////////////////////////////////////////////// STYLE

const Header = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.gray_5};
  padding: ${space(3)} 0px;
`;
const Title = styled.h2`
  ${typography('col_title')};
  flex-basis: 70%;
  /* border: 1px solid red; */
`;

const DetailsTable = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space(6)};
  /* border: 1px solid red; */
  & h2 {
    ${typography('h_medium')};
  }
`;

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
  /* border: 1px solid red; */
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  /* border: 1px solid red; */
`;
const OrderNumber = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0;
`;
const Number = styled.div`
  padding-left: 4px;
  font-weight: 500;
  color: #1d1221;
`;
const PaymentType = styled.div`
  padding-left: 4px;
  font-weight: 500;
  color: #1d1221;
`;
const PaymentTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0;
`;
const OrderTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 6px 0;
  align-items: center;
`;
const InfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  /* border: 1px solid green; */
`;
const OrderType = styled.h3`
  color: ${theme.colors.ink};
  font-weight: 500;
  align-items: center;
  text-decoration: none;
  /* border:1px solid green; */
  @media (max-width: 863px) {
    font-size: 14px;
  }
`;
const InfoRight = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  /* border: 1px solid purple; */
`;

const DataOrder = styled.p`
  font-size: ${typography('t_m')};
  text-align: right;
`;
const DetailsTableHeader = styled.div`
  display: flex;
  color: ${theme.colors.gray_4};
  font-style: ${typography('table_title')};
  margin-top: ${space(9)};
  padding-bottom: ${space(3)};
  border-bottom: 1px solid ${theme.colors.gray_5};
`;

const Pcategory = styled.p`
  ${typography('product_category')};
`;
const Ptitle = styled.p`
  ${typography('product_name')};
`;
const Pdescription = styled.p`
  ${typography('product_description')};
`;

const Cell1 = styled.div`
  display: flex;
  align-items: center;
  width: 10%;
`;
const Cell2 = styled.div`
  width: 65%;
  /* border: 1px solid red; */
`;

const Cell3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 25%;
`;
const DetailsTableRow = styled.div`
  display: flex;
  padding: 16px 0;
  min-height: 60px;
  /* border-bottom: 1px solid #bebec3; */
`;
const DetailsTableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${theme.colors.gray_5};
  padding-top: ${space(6)};
  text-align: right;
  /* border:1px solid red; */
`;

const Count = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-between;
  /* border:1px solid green; */

  & h4 {
    width: 30%;
    font-size: ${typography('t_xl')};
    color: ${theme.colors.ink};
  }
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: left;
  padding-right: 32px;
  font-size: 14px;
  line-height: 1.3em;
  /* border:1px solid brown; */
`;
const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 40px;
  padding: 12px 16px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.order.status === orderStatus.ACCEPTED ||
    props.order.status === orderStatus.FULFILLED
      ? '#ecf6f7'
      : props.order.status === orderStatus.REJECTED
      ? '#feeaea'
      : '#f8f8f8'};

  /* border:1px solid blue; */

  @media (max-width: 863px) {
    width: 70%;
  }
  @media (max-width: 667px) {
    width: 70%;
    flex-direction: column;
  }
`;
const LeftActions = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  justify-content: space-around;
  /* border: 1px solid orange; */
`;
const RightActions = styled.div`
  width: 30%;
  align-items: center;
`;
const LeftButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  /* border: 1px solid green; */
`;
const OrderFeedback = styled.div`
  display: flex;
  /* border:1px solid red; */
`;
const Accepted = styled.h4`
  color: #07aa67;
`;
const Failed = styled.h4`
  color: #f99500;
`;
const Rejected = styled.h4`
  color: #c70303;
`;
const ProcessFeedback = styled.p`
  margin-top: ${space(3)};
  font-size: 12px;
  color: #4e4e5d;
  /* border:1px solid green; */
`;

// const HiddenOrderPrint = styled.div`
//   display: none;
// `;
