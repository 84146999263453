// LIBRARIES
import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';

// CONTEXT
import StoreContext from '../../AppState/StoreContext';

// COMPONENTS
import DroppableArea from './DroppableArea';
import MenuForm from '../MenuEditor/MenuForm';
import DraggableHandle from '../../UI/ScDraggableCardWrapper';
import {MenuCard} from '../../UI/MenuCard';
import TTButton from '../../UI/TTButton';
import TTIcon from '../../UI/TTIcon';

// STYLES
import space from '../../../styles/space';

const MenuList = (props) => {
  // Context
  const globalStateStore = React.useContext(StoreContext);
  const {state} = globalStateStore;

  const place = state.place;
  const menuobj = state.menu;
  const menus = menuobj.menu;
  const webMenuEnabled = place.web_menu_enabled;

  let selected = parseInt(props.selectedElements.menu);

  const isInEditMode = props.currentlyEditing.type === 'MENU' ? true : false;
  const editingIndex = isInEditMode ? props.currentlyEditing.index : null;

  const openEditForm = (menuIndex) => {
    props.setCurrentlyEditingCallback({type: 'MENU', index: menuIndex});
  };

  const openAddForm = () => {
    let newIndex = menus ? menus.length + 1 : 1; //Todo: This is probably bugged. shouldn't it be just menus.length?
    props.setCurrentlyEditingCallback({
      type: 'MENU',
      index: newIndex,
    });
  };

  const onDragEnd = (result) => {
    const {destination, source} = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newMenu = Array.from(menus);
    const [removed] = newMenu.splice(source.index, 1);
    newMenu.splice(destination.index, 0, removed);
    // Updated the menu order
    props.updatePlaceMenuCallback({menu: newMenu});
    props.setSelectedElementsCallback({
      ...props.selectedElements,
      menu: destination.index,
    });
  };

  return (
    <>
      <DroppableArea onDragEnd={onDragEnd} droppableId={'menus'}>
        {menus &&
          menus.map((menu, key) => (
            <Draggable
              draggableId={key.toString()}
              index={key}
              key={key}
              // isDragDisabled={selected !== key}
            >
              {(provided, snapshot) => (
                <DraggableCardWrapper
                  {...provided.draggableProps}
                  selected={selected === key ? true : false}
                  ref={provided.innerRef}>
                  <DraggableHandle
                    {...provided.dragHandleProps}
                    isHovered={'ontouchstart' in window ? true : false}
                    isDragging={snapshot.isDragging}>
                    <ScDotsHandle>
                      <TTIcon icon="dotshandle" />
                    </ScDotsHandle>
                  </DraggableHandle>
                  <Card key={key}>
                    {isInEditMode && key === editingIndex ? (
                      // Edit form
                      <MenuForm
                        index={key}
                        formType="EDIT"
                        setCurrentlyEditingCallback={
                          props.setCurrentlyEditingCallback
                        }
                        updatePlaceMenuCallback={props.updatePlaceMenuCallback}
                      />
                    ) : (
                      <MenuCard
                        menus={menus}
                        menu={menu}
                        menuKey={key}
                        webMenuEnabled={webMenuEnabled}
                        selected={selected}
                        selectedElements={props.selectedElements}
                        setSelectedElementsCallback={
                          props.setSelectedElementsCallback
                        }
                        editCallBack={() => openEditForm(key)}
                        updatePlaceMenuCallback={props.updatePlaceMenuCallback}
                      />
                    )}
                  </Card>
                </DraggableCardWrapper>
              )}
            </Draggable>
          ))}
      </DroppableArea>

      {!isInEditMode && menus.length === 0 ? (
        <BlankStateMessage>
          Crea la prima voce del tuo menu, cliccando su Aggiungi menu
        </BlankStateMessage>
      ) : null}

      {/* Add form */}
      {isInEditMode && editingIndex === menus.length + 1 ? (
        <MenuForm
          formType="ADD"
          updatePlaceMenuCallback={props.updatePlaceMenuCallback}
          setCurrentlyEditingCallback={props.setCurrentlyEditingCallback}
        />
      ) : (
        <OpenForm>
          <TTButton icon="plus" variant="text" onClick={openAddForm}>
            Aggiungi menu
          </TTButton>
        </OpenForm>
      )}
    </>
  );
};
export default MenuList;

////////////////////////////////////////////////////////////

const OpenForm = styled.div`
  padding: ${space(6)} ${space(4)};
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const DraggableCardWrapper = styled.div`
  display: flex;
  background-color: ${(props) =>
    props.selected === true ? '#F9F8F8' : 'inherit'};
  border-left: ${(props) =>
    props.selected === true ? '4px solid #3DA8AB' : 'none'};
`;

const Card = styled.div`
  flex-grow: 1;
`;

const ScDotsHandle = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

const BlankStateMessage = styled.div`
  font-size: 14px;
  line-height: 1.3em;
  color: #4e4e5d;
  padding: 16px;
`;