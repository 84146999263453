import React from 'react';
import styled from 'styled-components';
// import {variant, space, size, color, border} from 'styled-system';

import theme from '../../styles/theme';
const {colors} = theme;

const TTInputNumber = (props) => {
  const inputElement = React.useRef(null);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      inputElement.current.blur();
    }
  };

  return (
    <InputNumber
      key={props.passedKey || props.id}
      active={props.active}
      htmlFor={props.id}>
      <input
        ref={inputElement}
        type="number"
        id={props.id}
        name={props.id}
        {...props}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={props.onBlur}
        onKeyPress={handleKeyPress}
        active={`${props.active}`}
        inputMode="numeric"
      />
      <span className="interalLabel">{props.label}</span>
    </InputNumber>
  );
};
export default TTInputNumber;

const InputNumber = styled.label`
  display: flex;
  opacity: ${(props) => (props.active ? 1 : 0.5)};

  & input {
    width: 100%;
    border-radius: 4px;
    text-align: right;
    color: ${colors.ink};
    border-style: solid;
    height: 32px;
  }

  & .interalLabel {
    padding-left: 8px;
    cursor: pointer;
    position: relative;
    align-self: center;
  }
`;
