import React from 'react';
import styled from 'styled-components';
import {variant, space, size, color, border} from 'styled-system';

import theme from '../../styles/theme';
const {colors} = theme;

const TTInput = (props) => {
  const inputElement = React.useRef(null);

  const handleKeyPress = (e) => {
    // if (e.key === 'Enter') {
    //   console.log('TTINPUT ENTER');
    //   console.log(inputElement);
    //   inputElement.current.blur();
    // }
  };

  return (
    <Label
      {...props}
      key={props.passedKey || props.id}
      active={props.active === 'true'} //if is disabled
      htmlFor={props.id}
      tabIndex={props.tabindex}>
      <span className="interalLabel">{props.label}</span>
      <Input
        ref={inputElement}
        type={props.type || 'text'}
        id={props.id}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onKeyPress={handleKeyPress}
        borderWidth={0}
        borderColor={colors.grays[3]} //TODO - Solve this with string
        borderRadius={0}
        mb={1}
        p={2}
        variant={props.variant}
      />
      {props.ErrorMessage && <ErrorMessage>{props.ErrorMessage}</ErrorMessage>}
    </Label>
  );
};
export default TTInput;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  border-style: solid;

  &:hover {
    ${/*TODO - Solve this with string*/ ''};
    border-color: ${colors.ink};
  }

  &:focus {
    ${/*TODO - Solve this with string*/ ''};
    border-color: ${colors.ink};
    outline: none;
  }

  &:active {
    ${/*TODO - Solve this with string*/ ''};
    border-color: ${colors.ink};
    outline: none;
  }

  ${variant({
    variants: {
      large: {
        height: 7,
      },
      small: {
        height: 6,
      },
    },
  })};

  ${space};
  ${size};
  ${color};
  ${border};
`;

const ErrorMessage = styled.p``;
