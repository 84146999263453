import * as React from 'react';
import theme from '../../../styles/theme';

function CheckMark(props) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        fill={props.fill || theme.colors.grays[1]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.06066 7.43934L11.5 2L13.6213 4.12132L6.06066 11.682L1 6.62132L3.12132 4.5L6.06066 7.43934Z"
      />
    </svg>
  );
}

export default CheckMark;
