import React from 'react';
import styled from 'styled-components';
// import {variant, space, size, color, border} from 'styled-system';
// import theme from '../../styles/theme';
import {toEuro} from '../../utils/money';
// const {colors} = theme;

const VariantWithOptionsReadOnly = (props) => {
  return (
    <>
      <WrapperReadOnly>
        <HeaderWrapper>
          <Heading>{props.variant.name}</Heading>
          <Heading>{props.inheritFrom}</Heading>
        </HeaderWrapper>
        {props.variant.options.map((opt) => {
          return (
            <ReadOnlyOption key={opt.id}>
              <span
                style={{textDecoration: opt.active ? 'none' : 'line-through'}}>
                {opt.name}
              </span>
              <span
                style={{textDecoration: opt.active ? 'none' : 'line-through'}}>
                + {toEuro(opt.price_increase)} €
              </span>
            </ReadOnlyOption>
          );
        })}
      </WrapperReadOnly>
    </>
  );
};

export default VariantWithOptionsReadOnly;

const WrapperReadOnly = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 16px;
  border-radius: 4px;
  background-color: #f0e9e9;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Heading = styled.h3`
  font-size: 14px;
  font-weight: medium;
  color: #4e4e5d;
  margin-bottom: 8px;
`;
const ReadOnlyOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
  font-size: 14px;
  color: #737383;
`;
