import * as React from 'react';
import theme from '../../../styles/theme';

function DeleteOption(props) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89457 6L11.8147 1.07985C12.0618 0.832816 12.0618 0.432302 11.8147 0.185272C11.5677 -0.0617574 11.1672 -0.0617574 10.9202 0.185272L6 5.10543L1.07985 0.185272C0.832816 -0.0617574 0.432302 -0.0617574 0.185272 0.185272C-0.0617574 0.432302 -0.0617574 0.832816 0.185272 1.07985L5.10543 6L0.185272 10.9202C-0.0617574 11.1672 -0.0617574 11.5677 0.185272 11.8147C0.432302 12.0618 0.832816 12.0618 1.07985 11.8147L6 6.89457L10.9202 11.8147C11.1672 12.0618 11.5677 12.0618 11.8147 11.8147C12.0618 11.5677 12.0618 11.1672 11.8147 10.9202L6.89457 6V6Z"
        fill={props.fill || theme.colors.grays[2]}
      />
    </svg>
  );
}

export default DeleteOption;
