// LIBRARIES
import React from 'react';
import {Route, Link} from 'react-router-dom';
import styled from 'styled-components';
import {DateTime} from 'luxon';

// CONTEXT
import StoreContext from '../../AppState/StoreContext';
import {FirebaseContext} from '../../Firebase';

// COMPONENTS
import OrderDetails from './OrderDetails';
import {Flex} from '../../UI/BoxFlex';
import TTIcon from '../../UI/TTIcon';

// Style
import theme from '../../../styles/theme';
import space from '../../../styles/space';
import typography from '../../../styles/typography';
import OrderStatus from '../../../constants/OrderStatus';
import {Howl} from 'howler';

const AlertSound = require('../../../assets/alert.wav');

const OrderManager = (props) => {
  // CONTEXT
  const globalStateStore = React.useContext(StoreContext);
  const {state, dispatch} = globalStateStore;
  const firebase = React.useContext(FirebaseContext);

  // STATES
  // const [loading, setLoading] = React.useState(true);
  const [orderList, setOrderList] = React.useState([]);
  const [openOrderCounter, setOpenOrderCounter] = React.useState(0);

  const alertSoundRef = React.useRef(
    new Howl({
      src: [AlertSound],
    }),
  );

  React.useEffect(() => {
    if (state.place) {
      const currentTime = new Date().getTime() / 1000;
      const yesterdayTime = currentTime - 24 * 3600; // 24 ore fa

      const timeZone = state.place.hasOwnProperty('timezone')
        ? state.place.timezone
        : 'Europe/Rome';
      const today = DateTime.local().setZone(timeZone).toISODate();
      console.log(yesterdayTime);
      const unsubscribe = firebase.firestore
        .collection(`orders`)
        .where('place_id', '==', state.place.id)
        .where('order_epoch', '>', yesterdayTime)
        .orderBy('order_epoch', 'desc')
        // accessing charge.created automatically filters out orders without a successfully processed charge
        .onSnapshot(
          (ordersSnapshot) => {
            const orders = [];
            ordersSnapshot.forEach((order) => {
              let orderObject = order.data();

              // If ordering with older Tomatoma, order_type will be missing.
              if (!orderObject.hasOwnProperty('order_type')) {
                orderObject.order_type = 'DELIVERY';
              }

              // If ordering with older Tomatoma, delivery_place will be called deliveryPlace
              if (!orderObject.hasOwnProperty('delivery_place')) {
                orderObject.delivery_place = orderObject.deliveryPlace;
              }

              const orderDate = DateTime.fromMillis(
                orderObject.order_epoch * 1000,
              )
                .setZone(timeZone)
                .toISODate();

              if (
                // Initially we only include these orders in the set to show
                [
                  OrderStatus.ACCEPTED,
                  OrderStatus.FULFILLED,
                  OrderStatus.REJECTED,
                  OrderStatus.PAYMENT_INTENT,
                ].includes(orderObject.status)
              ) {
                // But only the orders with PAYMENT_INTENT (new orders) from yesterday are included
                if (
                  orderDate === today ||
                  (orderDate !== today &&
                    orderObject.status === OrderStatus.PAYMENT_INTENT)
                ) {
                  orders.push({...orderObject, id: order.id});
                }
              }
            });

            setOrderList(orders);
          },
          (err) => {
            console.error(err);
          },
        );
      return () => {
        unsubscribe();
      };
    }
  }, [firebase.firestore, state.place]);

  // Effect to update new order counter
  React.useEffect(() => {
    const counter = orderList.filter(
      (order) => order.status === OrderStatus.PAYMENT_INTENT,
    );
    setOpenOrderCounter(counter.length);
  }, [orderList]);

  // Effect to play sound for new order if needed
  React.useEffect(() => {
    if (orderList.length > 0) {
      const localLatestOrderId = orderList[0].id;
      // Check if it's the first render. We use null to indicate it in state.
      if (state.latestOrderId === undefined) {
        dispatch({
          type: 'SET_LATEST_ORDER_ID',
          payload: null,
        });
      } else {
        if (
          state.latestOrderId !== null &&
          state.latestOrderId !== localLatestOrderId
        ) {
          alertSoundRef.current.play();
          dispatch({
            type: 'SET_NEW_ORDER',
            payload: true,
          });
        }

        // update the latest order id in the global state
        dispatch({
          type: 'SET_LATEST_ORDER_ID',
          payload: localLatestOrderId,
        });
      }
    }
  }, [orderList, dispatch, state.latestOrderId, state.newOrder]);

  //   <audio ref={alertSoundRef}>
  //   <source src={AlertSound} type="audio/wav" />
  // </audio>

  return (
    <Dashboard>
      <Left>
        <Header>
          <TitleNewOrder>Nuovi Ordini ({openOrderCounter}) </TitleNewOrder>
          <LabelTable>Pagamento</LabelTable>
        </Header>
        <ScOrdersList>
          {orderList.map((order, key) => {
            let order_sequence = order.order_sequence ?? '';

            if (
              !state.place.use_tt_numbering &&
              order.status !== 'FULFILLED' &&
              order.status !== 'ACCEPTED'
            ) {
              order_sequence = '';
            }

            return (
              <ScOrderCardLink
                to={`/orders/${order.id}`}
                key={key}
                taken={
                  order.status === OrderStatus.PAYMENT_INTENT ||
                  order.status === OrderStatus.ACCEPTED
                    ? 'false'
                    : 'true'
                }
                active={
                  props.match.params.orderId === order.id ? 'true' : 'false'
                }>
                <NumberWrapper>
                  <IconWrapper>
                    {(order.status === OrderStatus.FULFILLED ||
                      order.status === OrderStatus.AUTO_FULFILLED) && (
                      <TTIcon icon="checkmark" />
                    )}
                  </IconWrapper>
                  <Number>{order_sequence}</Number>
                </NumberWrapper>
                <CardLeftWrapper>
                  {order.order_datetime}

                  <OrderKind>
                    {order.order_type === 'DELIVERY'
                      ? order.delivery_place
                      : 'Ritiro'}
                  </OrderKind>
                </CardLeftWrapper>
                <CardRightWrapper>
                  <PaymentKind>
                    {order.chosen_payment_method === 'cash'
                      ? 'Alla Consegna\n'
                      : null}
                    {order.chosen_payment_method === 'card' ? 'Carta' : null}
                  </PaymentKind>
                  <OrderStatusDetail>
                    {order.status === OrderStatus.ACCEPTED && (
                      <PreparationSpan>In preparazione</PreparationSpan>
                    )}
                    {order.status === OrderStatus.REJECTED && (
                      <RejectedSpan>Rifiutato</RejectedSpan>
                    )}
                    {order.status === OrderStatus.FULFILLED && (
                      <ConcludedSpan>Concluso</ConcludedSpan>
                    )}
                  </OrderStatusDetail>
                </CardRightWrapper>
              </ScOrderCardLink>
            );
          })}
        </ScOrdersList>
      </Left>
      <OrderDetailsWrapper>
        <Route path={`/orders/:orderId`} component={OrderDetails} />
      </OrderDetailsWrapper>
    </Dashboard>
  );
};

export default OrderManager;

////////////////////////////////////////////////////////////

const Dashboard = styled(Flex)`
  min-height: 100%;
  justify-content: space-evenly;
  max-width: ${theme.breakpoints[2]};
  margin: 0 auto;
  padding-top: ${theme.space[4] + 'px'};
`;
const PreparationSpan = styled.span`
  color: #07aa6f;
`;
const RejectedSpan = styled.span`
  color: #c70303;
`;
const ConcludedSpan = styled.span`
  color: #00a2c6;
`;
const Left = styled.div`
  width: 38%;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.gray_5};
  padding: ${theme.space[3] + 'px'};
  justify-content: space-between;
  /* border: 1px solid goldenrod; */
`;

const TitleNewOrder = styled.h2`
  ${typography('col_title')};

  @media (max-width: 667px) {
    flex-basis: 60%;
  }
`;

// CONTENT INSIDE A CARD

const NumberWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 28px;
  margin-right: ${space(5)};
  /* border: 1px solid red; */
`;

const IconWrapper = styled.div`
  box-sizing: border-box;
  min-width: 18px;
  padding-right: 8px;
  /* border: 1px solid green; */
`;

const Number = styled.div`
  /*  */
  ${typography('t_m')}
`;

const CardLeftWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;
const CardRightWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  flex-direction: column;
`;
const OrderKind = styled.h3`
  color: ${theme.colors.ink};
  font-weight: 500;
  text-decoration: none;
  /* border:1px solid green; */
  @media (max-width: 863px) {
    font-size: 14px;
  }
`;
const LabelTable = styled.div`
  color: ${theme.colors.ink};
  font-weight: 500;
  text-align: right;
  text-decoration: none;
  font-size: 14px;
  padding-right: 12px;
  @media (max-width: 863px) {
    font-size: 14px;
  }
`;
const PaymentKind = styled.div`
  text-align: right;
  font-weight: 500;
  width: 100px;
  font-size: 14px;
  /* border:1px solid red; */
`;
const OrderStatusDetail = styled.div`
  text-align: right;
  font-weight: 500;
  width: 100px;
  font-size: 14px;
  /* border:1px solid red; */
`;

const ScOrdersList = styled.div`
  padding-top: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
`;

const ScOrderCardLink = styled(Link)`
  position: relative;
  text-decoration: none;
  display: flex;
  align-items: center;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.white};
  color: ${theme.colors.ink};
  ${typography('t_m')};
  padding: ${space(5)};
  padding-left: 0;

  &:hover {
    background-color: ${theme.colors.grays[5]};
    border-bottom: 1px solid ${theme.colors.grays[4]};
  }
  opacity: ${(props) => (props.taken === 'true' ? '0.2' : 1)};
  background-color: ${(props) =>
    props.active === 'true' ? theme.colors.grays[5] : theme.colors.white};
  border-bottom-color: ${(props) =>
    props.active === 'true' ? theme.colors.primary : null};
`;

const OrderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  width: 45%;
`;
