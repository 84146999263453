import React, {useReducer} from 'react';
import StoreContext from './StoreContext';
const {Provider} = StoreContext;

const StateProvider = ({children}) => {
  const [state, dispatch] = useReducer((state, action) => {
    console.log(action.type);

    switch (action.type) {
      case 'SET_PLACE':
        return {
          ...state,
          place: action.payload,
        };

      case 'SET_MENU':
        return {
          ...state,
          menu: action.payload,
        };

      case 'SET_CONFIG':
        return {
          ...state,
          config: action.payload,
        };

      case 'SET_MANAGER':
        return {
          ...state,
          manager: action.payload,
        };

      case 'SET_LATEST_ORDER_ID':
        return {
          ...state,
          latestOrderId: action.payload,
        };

      case 'SET_NEW_ORDER':
        return {
          ...state,
          newOrder: action.payload,
        };

      case 'LOGOUT':
        return {
          place: null,
          menu: null,
          newOrder: null,
          latestOrderId: null,
          manager: null,
          config: null,
        }

      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  }, {}); // initialState passed as second argument of useReduces

  return <Provider value={{state, dispatch}}>{children}</Provider>;
};

export default StateProvider;
