// LIBRARIES
import React from 'react';
import styled from 'styled-components';

// CONTEXT
import StoreContext from '../../AppState/StoreContext';

// COMPONENTS
import TTButton from '../../UI/TTButton';

// STYLES
// import theme from '../../../styles/theme';

////////////////////////////////////////////////////////////////

const FormActions = (props) => {
  // Context
  //
  const globalStateStore = React.useContext(StoreContext);
  const {state} = globalStateStore;

  // States
  //
  const menuobj = state.menu;
  const menu = menuobj.menu;

  const {items, arrayList, formType, index} = props;

  const canDelete = () => {
    return !(arrayList && arrayList.length);
  };

  /************************************************
   * Delete the element from the items array
   ************************************************/
  const handleDelete = (e) => {
    items.splice(index, 1);

    props.updatePlaceMenuCallback({
      menu: menu,
    });

    props.handleCancelCallback(e);
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <Actions disabled={props.disabled}>
      <Action>
        {formType === 'EDIT' && canDelete() && (
          <TTButton
            type="button"
            icon="trashcan"
            iconFill="white"
            disabled={props.disabled ? 'disabled' : false}
            onClick={() => {
              handleDelete();
            }}
          />
        )}
      </Action>
      <Action>
        <TTButton
          type="button"
          color="white"
          variant="text"
          disabled={props.disabled ? 'disabled' : false}
          onClick={(e) => props.handleCancelCallback(e)}>
          Annulla
        </TTButton>
        <TTButton
          disabled={props.disabled ? 'disabled' : false}
          variant="primary"
          type="submit"
          value="Salva">
          {formType === 'ADD' ? 'Aggiungi' : 'Salva'}
        </TTButton>
      </Action>
    </Actions>
  );
};

export default FormActions;

////////////////////////////////////////////////////////////

const Actions = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  padding: 16px 12px;
  margin-top: 40px;
  justify-content: space-between;
  background-color: #20293e;
  opacity: ${(props) => (props.disabled ? 0.4 : 1.0)};
`;
const Action = styled.div`
  display: flex;
  flex-direction: row;
`;
