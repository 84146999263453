// LIBRARIES
import React, {useContext, useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';

// STYLE
import './styles/App.css';

// CONTEXT
import StoreContext from './components/AppState/StoreContext';
import {FirebaseContext} from './components/Firebase';

// COMPONENTS
import Navigation from './components/UI/Navigation';

// PAGES
import LogIn from './components/Pages/LogIn';
import PasswordForget from './components/Pages/PasswordForget';
import SignUp from './components/Pages/SignUp';
import {OrderManager} from './components/Pages/OrderManager';
import MenuEditor from './components/Pages/MenuEditor';
import Settings from './components/Pages/Settings/Settings';
import Account from './components/Pages/AccountPage/Account';
import Report from './components/Pages/Report/Report';
import StripeConnect from './components/Pages/StripeConnect/StripeConnect';

// UTILS
import * as ROUTES from './constants/routes';

let manager = undefined;

const App = () => {
  // instantiate the global state
  const globalStateStore = useContext(StoreContext);
  const {state, dispatch} = globalStateStore;

  const firebase = React.useContext(FirebaseContext);

  const [user, loading, firebaseUserError] = useAuthState(firebase.auth);

  useEffect(() => {
    if (user) {
      let unsubscribe;

      // const [value, loading, error] = useDocumentData(reference, options);

      // Get the manager data
      firebase.firestore
        .doc(`managers/${user.uid}`)
        .get()
        .then((querySnapshot) => {
          manager = querySnapshot.data();

          // Later the app needs support for multiple places
          const placeId =
            manager?.places?.length > 0 ? manager.places[0] : null;

          if (placeId) {
            console.log('place_id', placeId);

            firebase.firestore
              .doc(`places_config/${placeId}`)
              .get()
              .then((querySnapshot) => {
                dispatch({
                  type: 'SET_CONFIG',
                  payload: querySnapshot.data() ?? null,
                });
              });

            firebase.firestore
              .doc(`places_menu/${placeId}`)
              .get()
              .then((querySnapshot) => {
                if (querySnapshot.empty) {
                  throw new Error('Menu not found!');
                }

                const data = querySnapshot.data();

                dispatch({
                  type: 'SET_MENU',
                  payload: data || null,
                });
              })
              .catch((err) => {
                throw err;
              });

            unsubscribe = firebase.firestore
              .doc(`places/${placeId}`)
              .onSnapshot((querySnapshot) => {
                const current_place = querySnapshot.data();
                console.log('Place state set');
                dispatch({
                  type: 'SET_PLACE',
                  payload: current_place,
                });
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // returned function will be called on component unmount
      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [user, firebase.firestore, dispatch]);

  ////////////////////////////////////

  if (firebaseUserError) {
    // We had an error in retriving Firebase User
    return (
      <div>
        <p>Error: {firebaseUserError}</p>
      </div>
    );
  }

  if (loading) {
    // We are still loading the user or the place
    return (
      <div>
        <p>Loading user data...</p>
      </div>
    );
  }

  return (
    <>
      {user ? (
        state.place && state.menu ? (
          <Router>
            <Navigation />
            <Switch>
              <Route
                exact
                path={ROUTES.ROOT}
                render={() => <Redirect to={ROUTES.ORDERS} />}
              />
              <Route exact path={ROUTES.ORDERS} component={OrderManager} />
              <Route path={ROUTES.ORDER_WITH_ID} component={OrderManager} />
              <Route exact path={ROUTES.EDITOR} component={MenuEditor} />
              <Route path={ROUTES.EDITOR_MENU} component={MenuEditor} />
              <Route
                path={ROUTES.EDITOR_MENU_CATEGORY}
                component={MenuEditor}
              />
              <Route
                path={ROUTES.SETTINGS}
                render={(props) => (
                  <Settings
                    {...props}
                    place={state.place}
                    config={state.config}
                  />
                )}
              />
              <Route path={ROUTES.ACCOUNT} component={Account} />
              <Route path={ROUTES.REPORT} component={Report} />
              <Route
                path={ROUTES.CONNECT_TO_STRIPE}
                component={StripeConnect}
              />
              <Route
                exact
                path={ROUTES.SIGN_UP}
                render={() => <Redirect to={ROUTES.ROOT} />}
              />
              <Route
                exact
                path={ROUTES.LOG_IN}
                render={() => <Redirect to={ROUTES.ROOT} />}
              />
              {/* Todo: Create a 404 page not found component/template */}
              <Route render={() => <h1>PAGE NOT FOUND</h1>} />
            </Switch>
          </Router>
        ) : (
          <div>Loading place data</div>
        )
      ) : (
        <Router>
          <Switch>
            <Route path={ROUTES.SIGN_UP} component={SignUp}>
              {/* <Route path=":id" render={(props) => <SignUp />} /> */}
            </Route>
            <Route exact path={ROUTES.LOG_IN} render={() => <LogIn />} />
            <Route
              exact
              path={ROUTES.PASSWORD_FORGET}
              render={() => <PasswordForget />}
            />
            {/* Fallback, redirect on login */}
            <Route render={() => <Redirect to={`/login`} />} />
          </Switch>
        </Router>
      )}
    </>
  );
};

export default App;

// TODO: Look at this to secure user registration
// https://stackoverflow.com/questions/38357554/how-to-disable-signup-in-firebase-3-x

///////////////////////////////////////////////////////////////
