import React from 'react';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

import {FirebaseContext} from './../Firebase';

// Style
import theme from '../../styles/theme';
import space from '../../styles/space';
import typography from '../../styles/typography';
import TTButton from '../UI/TTButton';

// import * as ROUTES from "../constants/routes";

const logoImage = require('../../assets/Logo-Tomatoma.png');

const LogInPage = () => {
  //
  const firebase = React.useContext(FirebaseContext);
  const [formError, setFormError] = React.useState('');

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('É necessario inserira una email valida')
      .required('É necessario inserira una email valida'),
    password: Yup.string().required('É necessario inserire la password'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
    // validate: (values) => validateHandler(values),
    validationSchema: validationSchema,
  });

  const onSubmitHandler = (values) => {
    console.log('Logging in...');

    firebase
      .doSignInWithEmailAndPassword(values.email, values.password)
      .then((authUser) => {
        // console.log(authUser);
      })
      .catch((error) => {
        setFormError(error);
      });
  };

  return (
    <SignInWrapper>
      <img alt="Tomatoma Logo" src={logoImage} style={{width: '140px'}} />
      <Title>Accedi al tuo account</Title>
      <StyleForm onSubmit={formik.handleSubmit}>
        <StyleLabel htmlFor="email">Email</StyleLabel>
        <StyleInput
          id="email"
          name="email"
          type="email"
          placeholder="Indirizzo Email"
          tabindex="1"
          {...formik.getFieldProps('email')} // value, onChange, onFocus and onBlur
        />
        {formik.touched.email && formik.errors.email && (
          <ErrorText>{formik.errors.email}</ErrorText>
        )}

        <StyleLabel htmlFor="password">Password</StyleLabel>
        <StyleInput
          name="password"
          type="password"
          placeholder="Password"
          tabindex="2"
          {...formik.getFieldProps('password')} // value, onChange, onFocus and onBlur
        />
        {formik.touched.password && formik.errors.password && (
          <ErrorText>{formik.errors.password}</ErrorText>
        )}

        {formError && <ErrorText>{formError.message}</ErrorText>}
        <StLinkPassword to="/password-forget">
          <p>Password dimenticata?</p>
        </StLinkPassword>


        <TTButton variant="big" type="submit" tabindex="3">
          Entra
        </TTButton>
        <SignupInstead>
          <p>Nuovo su Tomatoma? </p>
          <StLink to="/signup">Crea il tuo account</StLink>
        </SignupInstead>
      </StyleForm>
    </SignInWrapper>
  );
};

export default LogInPage;

//////////////////////////////////////////////////////////////////

const SignInWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0rem;
  background-color: ${theme.colors.yellow};
  height: 100%;
  padding-bottom: 56px;
`;

const Title = styled.h1`
  ${typography('page_title')};
  margin-top: ${space(7)};
  color: ${theme.colors.ink};
  text-align: center;
`;

const StyleForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 360px;
  margin: 0 auto;
`;

const StyleLabel = styled.label`
  ${typography('form_label')};
  font-weight: ${theme.fontWeights.normal};
  color: ${theme.colors.gray_2};
  display: flex;
  justify-content: left;
  margin: 24px 0 8px 0;
`;

const StyleInput = styled.input`
  ${typography('t_l')};
  border-radius: 8px;
  padding: 16px 8px;
  color: ${theme.colors.gray_4};
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.white};

  &:focus {
    border: 1px solid ${theme.colors.ink};
    color: ${theme.colors.ink};
    outline: none;
  }
`;
const SignupInstead = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${space(7)};
  justify-content: center;
`;

const StLink = styled(Link)`
  ${typography('t_l')};
  color: ${theme.colors.brand_color_2};
  font-weight: ${theme.fontWeights.medium};
  margin-left: ${space(3)};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.brand_color_2};
    border-bottom: 1px solid ${theme.colors.brand_color_2};
    cursor: pointer;
  }
`;
const StLinkPassword = styled(Link)`
  ${typography('t_m')};
  color: ${theme.colors.gray_2};
  font-weight: ${theme.fontWeights.normal};
  margin-top: ${space(4)};
  margin-bottom: ${space(9)};

  text-decoration: none;
  text-align: right;


  &:hover {
    color: ${theme.colors.ink};
    text-decoration: underline;
    cursor: pointer;
  }
`;

const ErrorText = styled.div`
  position: relative;
  top: 4px;
  left: 4px;
  ${typography('inputErrorMessage')};
  color: ${theme.colors.error_ink};
  font-weight: ${theme.fontWeights.normal};
`;
