// LIBRARIES
import React, {useEffect, useCallback, useState, useContext} from 'react';
import {useAuthState} from 'react-firebase-hooks/auth';
import {Route, useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {DateTime} from 'luxon';
import ReactModal from 'react-modal';

// CONTEXT
import StoreContext from '../../AppState/StoreContext';
import {FirebaseContext} from '../../Firebase';

//COMPONENTS
import MenuList from './MenuList';
import ItemsList from './ItemsList';
import TTIcon from '../../UI/TTIcon';
import CategoriesList from './CategoriesList';

// STYLES
import space from '../../../styles/space';
import theme from '../../../styles/theme';
// import typography from '../../../styles/typography';

// UTILS
import * as ROUTES from '../../../constants/routes';

////////////////////////////////////////////////////////////////

const MenuEditorPage = (props) => {
  // Context
  const globalStateStore = useContext(StoreContext);
  const {state, dispatch} = globalStateStore;
  const firebase = useContext(FirebaseContext);

  const [user] = useAuthState(firebase.auth);

  const place = state.place;
  const menuobj = state?.menu ?? null;
  const menus = menuobj?.menu ?? null;

  let history = useHistory();

  const [currentlyEditing, setCurrentlyEditing] = useState({
    type: null,
    index: null,
  });

  const [selectedElements, setSelectedElements] = useState({
    menu: 0,
    category: 0,
    item: 0,
  });

  const [uploadingStatus, setUploadingStatus] = useState({
    uploading: false,
    label: '',
  });

  /************************************************
   * Updates the menu for the current place
   ************************************************/
  const updatePlaceMenu = useCallback(
    (newMenu) => {
      if (newMenu && newMenu.menu !== 'undefined') {
        const newmenuobj = {
          updated_at: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
          updated_by: user.email,
          menu: newMenu.menu,
        };

        firebase.firestore
          .collection(`places_menu`)
          .doc(place.id)
          .update(newmenuobj)
          .then((ref) => {
            dispatch({
              type: 'SET_MENU',
              payload: newmenuobj,
            });
          });
      }
    },
    [firebase.firestore, dispatch, place.id, user.email],
  );

  /*=======================================
   * Effects
   =======================================*/
  useEffect(() => {
    // When the selection changes, update the url
    // Todo: this should take the current path in consideration. Right now it just reset to /0/0/0 every time you reload the URL
    history.push(
      `/menu-editor/${selectedElements.menu}/${selectedElements.category}/${selectedElements.item}`,
    );
  }, [selectedElements, history]);

  // When the order or the content of the menu items changes in the local state, update the database
  // useEffect(() => {
  //   if (Array.isArray(menus) && menus.length > 0) {
  //     updatePlaceMenu({
  //       menu: menus,
  //     });
  //   }
  // }, [menus, updatePlaceMenu]);

  /*=======================================
   *            ***Render***
   =======================================*/

  if (menus === null || menuobj === null) {
    return <></>;
  }

  if (menus) {
    return (
      <>
        <ReactModal
          ariaHideApp={false}
          isOpen={uploadingStatus.uploading}
          shouldCloseOnEsc={false}
          contentLabel="Upload Modal"
          style={{
            content: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: '40%',
              left: '15%',
              height: '20%',
              width: '70%',
              borderRadius: '8px',
            },
          }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'black',
              height: '100%',
            }}>
            <span>
              <h1>{uploadingStatus.label}</h1>
            </span>
          </div>
        </ReactModal>
        <ScMenuEditor>
          <ScColumnMenu>
            <ScHeaderTitles>
              <ScTitle>Menu</ScTitle>
              <ScHeaderIcons>
                {place.web_menu_enabled ? (
                  <TTIcon icon="table" alt="Table" title="Web Menu" />
                ) : null}
                <TTIcon icon="geopoint" alt="geopoint" title="Tomatoma" />
              </ScHeaderIcons>
            </ScHeaderTitles>
            <ScMenus>
              <Route
                path={ROUTES.EDITOR}
                render={(props) => {
                  return (
                    <MenuList
                      updatePlaceMenuCallback={updatePlaceMenu}
                      selectedElements={selectedElements}
                      setSelectedElementsCallback={setSelectedElements}
                      currentlyEditing={currentlyEditing}
                      setCurrentlyEditingCallback={setCurrentlyEditing}
                    />
                  );
                }}
              />
            </ScMenus>
          </ScColumnMenu>
          <ScColumnCategory>
            <ScHeaderTitles>
              <ScTitle>Categoria</ScTitle>
              <ScHeaderIcons>
                {place.web_menu_enabled ? (
                  <TTIcon icon="table" alt="Table" title="Web Menu" />
                ) : null}
                <TTIcon icon="geopoint" alt="Punto" title="Tomatoma" />
              </ScHeaderIcons>
            </ScHeaderTitles>
            <ScCategories>
              <Route
                path={ROUTES.EDITOR_MENU}
                render={(props) => (
                  <CategoriesList
                    updatePlaceMenuCallback={updatePlaceMenu}
                    selectedElements={selectedElements}
                    setSelectedElementsCallback={setSelectedElements}
                    currentlyEditing={currentlyEditing}
                    setCurrentlyEditingCallback={setCurrentlyEditing}
                  />
                )}
              />
            </ScCategories>
          </ScColumnCategory>
          <ScColumnItem>
            <ScHeaderTitles>
              <ScTitle>Prodotti</ScTitle>
              <ScHeaderIcons>
                {place.web_menu_enabled ? (
                  <TTIcon icon="table" alt="Table" title="Web Menu" />
                ) : null}
                <TTIcon icon="geopoint" alt="Punto" title="Tomatoma" />
              </ScHeaderIcons>
            </ScHeaderTitles>
            <ScItems>
              <Route
                path={ROUTES.EDITOR_MENU_CATEGORY}
                render={(props) => (
                  <ItemsList
                    updatePlaceMenuCallback={updatePlaceMenu}
                    selectedElements={selectedElements}
                    setSelectedElementsCallback={setSelectedElements}
                    currentlyEditing={currentlyEditing}
                    setCurrentlyEditingCallback={setCurrentlyEditing}
                    setUploadingStatus={setUploadingStatus}
                  />
                )}
              />
            </ScItems>
          </ScColumnItem>
        </ScMenuEditor>
      </>
    );
  } else {
    return null;
  }
};

export default MenuEditorPage;

////////////////////////////////////////////////////////////

const ScMenuEditor = styled.div`
  background-color: ${theme.colors.white};
  display: flex;
  flex: 1;
  margin: 0 auto;
  padding: 2em 1em 0;
  width: 100%;

  @media (max-width: 667px) {
    flex-wrap: wrap;
  }
`;

const ScHeaderTitles = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${theme.colors.gray_5};
  padding: ${space(3)} 0px;
  justify-content: space-between;
`;
const ScTitle = styled.h2`
  flex-basis: 70%;
  font-size: 18px;
`;

const ScHeaderIcons = styled.div`
  display: flex;
  padding-right: 2em;
  & svg {
    margin-left: ${space(6)};
  }
`;

const Column = styled.div`
  margin-right: 1.5em;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }
`;

const ScColumnMenu = styled(Column)`
  width: 25%;

  @media (max-width: 667px) {
    width: 100%;
  }
`;

const ScColumnCategory = styled(Column)`
  width: 25%;

  @media (max-width: 667px) {
    width: 100%;
  }
`;
const ScColumnItem = styled(Column)`
  width: 50%;

  @media (max-width: 667px) {
    width: 100%;
  }
`;

const ScMenus = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
`;

const ScCategories = styled.div``;

const ScItems = styled.div`
  display: flex;
  flex-direction: column;
`;
