import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import config from '../../constants/config';

const firebaseConfig = config.PRODUCTION_firebaseConfig;
const devFirebaseConfig = config.STAGING_FirebaseConfig;

class Firebase {
  auth = null;
  firestore = null;
  functions = null;
  storage = null;

  constructor() {
    if (process.env.REACT_APP_ENV_VAR === 'STAGING') {
      app.initializeApp(devFirebaseConfig);
    } else if (process.env.REACT_APP_ENV_VAR === 'PRODUCTION') {
      app.initializeApp(firebaseConfig);
    } else {
      console.error('Wrong ENV and missing firebase configuration');
    }

    this.auth = app.auth();
    this.firestore = app.firestore();
    /*
    if (window.location.hostname === 'localhost') {
      this.auth.useEmulator('http://localhost:9099');
      this.firestore.useEmulator('localhost', 8080);
    }
    */
    this.functions = app.functions();
    this.storage = app.storage;
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doOnAuthStateChange = (onChange) => this.auth.onAuthStateChanged(onChange);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
}

export default Firebase;
