import * as React from 'react';
import theme from '../../../styles/theme';

function Plus(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6V0H6v6H0v4h6v6h4v-6h6V6h-6z"
        fill={props.fill || theme.colors.grays[1]}
      />
    </svg>
  );
}

export default Plus;
