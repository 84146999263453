import React, {useState} from 'react';
import styled from 'styled-components';
import {/* variant, */ space /* size, color, border */} from 'styled-system';
import shortid from 'shortid';
import theme from '../../styles/theme';
import {cloneDeep} from 'lodash/fp';

// COMPONENTS
import TTButton from './TTButton';
import Variant from './Variant';

// const {colors} = theme;

const VariantList = (props) => {
  const [isEditingId, setIsEditingId] = useState(false);

  // Set that we are in editing mode with the ID of the variant we are editing
  // If there is passed in a prop with function setVariantsInEditingMode, then set this also
  const setEditModeWithId = (id) => {
    setIsEditingId(id);
    if (props.hasOwnProperty('setVariantsInEditingMode')) {
      props.setVariantsInEditingMode(id !== false ? true : false);
    }
  };

  /**
   * Add a new variant to the list with default values
   */
  const addVariant = () => {
    const id = shortid.generate();

    setEditModeWithId(id);

    const list = cloneDeep(props.list);

    const idopt1 = shortid.generate();
    const idopt2 = shortid.generate();

    list.push({
      id: id,
      name: 'Titolo Variante',
      active: true,
      options: [
        {
          id: idopt1,
          name: 'Nome Opzione',
          price_increase: 0,
          active: true,
        },
        {
          id: idopt2,
          name: 'Nome Opzione',
          price_increase: 0,
          active: true,
        },
      ],
    });

    props.updateVariantList(list);
  };

  /**
   *
   * @param {string} id Id of variant to delete
   */
  const deleteVariant = (id) => {
    setEditModeWithId(false);

    // Take a copy of the variantlist
    const list = cloneDeep(props.list);

    // Find the element to delete
    const idx = list.findIndex((variant) => variant.id === id);

    if (idx !== -1) {
      // Remove it, if it exists
      list.splice(idx, 1);
      // and update the list of variants
      props.updateVariantList(list);
    }
  };

  /**
   *
   * @param {any} updatedVariant Variant object to exhange
   */
  const updateVariant = (updatedVariant) => {
    setEditModeWithId(updatedVariant.id);

    const list = cloneDeep(props.list);
    const idx = list.findIndex((variant) => variant.id === updatedVariant.id);

    if (idx !== -1) {
      // Delete object and exchange we updated version
      list.splice(idx, 1, updatedVariant);
      props.updateVariantList(list);
    }
  };

  return (
    <StContainer>
      <Title>Varianti </Title>

      <OptionList>
        {props.list.length
          ? props.list.map((variant) => (
              <Variant
                key={variant.id}
                variant={variant}
                deleteVariant={deleteVariant}
                updateVariant={updateVariant}
                setEditModeWithId={setEditModeWithId}
                isEditingId={isEditingId}
              />
            ))
          : null}
      </OptionList>
      {/* <br clear="all" /> */}
      {isEditingId ? (
        <ActionWrapper>
          <Action>
            <TTButton
              type="button"
              icon="trashcan"
              iconFill={theme.colors.grays[3]}
              onClick={() => deleteVariant(isEditingId)}>
              Cancella Gruppo
            </TTButton>
            <ActionRight>
              <TTButton
                type="button"
                variant="text"
                onClick={(el) => {
                  setEditModeWithId(false);
                  props.handleCancelCallback(el);
                }}>
                Annulla
              </TTButton>
              <TTButton
                type="button"
                variant="primary"
                onClick={(el) => {
                  setEditModeWithId(false);
                  props.handleSaveCallback(el);
                }}>
                Salva
              </TTButton>
            </ActionRight>
          </Action>
        </ActionWrapper>
      ) : (
        <OpenForm>
          <ScTTButton
            // icon="plus"

            bg="dark_blue"
            variant="primary"
            type="button"
            onClick={addVariant}>
            Nuova Variante
          </ScTTButton>
        </OpenForm>
      )}
    </StContainer>
  );
};

export default VariantList;

const ScTTButton = styled(TTButton)`
  border-radius: 4px;
  color: #fff;
  &:hover {
    background-color: #20293e;
  }
`;

const StContainer = styled.div`
  flex: 1;
  flex-direction: column;
  padding: ${space(6)} ${space(4)};
`;

const OpenForm = styled.div`
  padding: ${space(6)} ${space(4)};
  cursor: pointer;
  border-top: 1px solid #e2e2e5;
  padding-top: 24px;
  &:hover {
    opacity: 1;
  }
`;

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #e2e2e5;
  padding: 16px 0;
`;
const Action = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border:1px solid red; */
`;
const ActionRight = styled.div`
  display: flex;
  /* border:1px solid red; */
`;

const Title = styled.h4`
  margin-top: 24px;
`;
const OptionList = styled.div`
  margin-top: 16px;
`;
