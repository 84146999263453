import * as React from 'react';
import theme from '../../../styles/theme';

function Alert(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fill={props.fill || theme.colors.grays[1]}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.14 2H7.86L2 7.86v8.28L7.86 22h8.28L22 16.14V7.86L16.14 2zM12 7a1 1 0 011 1v4a1 1 0 11-2 0V8a1 1 0 011-1zm-1 9a1 1 0 011-1h.01a1 1 0 110 2H12a1 1 0 01-1-1z"
      />
    </svg>
  );
}

export default Alert;
