// LIBRARIES
import React from 'react';
import {Draggable} from 'react-beautiful-dnd';
import styled from 'styled-components';

// CONTEXT
import StoreContext from '../../AppState/StoreContext';

//COMPONENTS
import DroppableArea from './DroppableArea';
// import DraggableCardWrapper from '../../UI/ScDraggableCardWrapper';
import CategoryForm from './CategoryForm';
import CategoryCard from '../../UI/CategoryCard';
import TTButton from '../../UI/TTButton';
import DraggableHandle from '../../UI/ScDraggableCardWrapper';
import TTIcon from '../../UI/TTIcon';

//STYLES
import space from '../../../styles/space';

////////////////////////////////////////////////////////////////

const CategoriesList = (props) => {
  // Context
  //
  const globalStateStore = React.useContext(StoreContext);
  const {state} = globalStateStore;

  const menuobj = state.menu;
  const menus = menuobj.menu;
  const menuID = props.selectedElements.menu;
  const webMenuEnabled = state.place.web_menu_enabled;

  const categories =
    menus !== 'undefined' && menus[menuID] && menus[menuID] !== 'undefined'
      ? menus[menuID].categories
      : null;

  let selected = parseInt(props.selectedElements.category);

  const isInEditMode =
    props.currentlyEditing.type === 'CATEGORY' ? true : false;
  const editingIndex = isInEditMode ? props.currentlyEditing.index : null;

  /************************************************
   * Set the state to open the editing mode for an existing category
   ************************************************/
  const openEditForm = (categoryIndex) => {
    props.setCurrentlyEditingCallback({
      type: 'CATEGORY',
      index: categoryIndex,
    });
  };

  /************************************************
   * Set the state to open the editing mode for a NEW category
   ************************************************/
  const openAddForm = (index) => {
    let newIndex = categories ? categories.length + 1 : 1; //Todo: This is probably bugged. shouldn't it be just menus.length?

    props.setCurrentlyEditingCallback({
      type: 'CATEGORY',
      index: newIndex,
    });
  };

  /************************************************
   * On drag end, update the menu order and uses
   * the callback to update it on the DB
   ************************************************/
  const onDragEnd = (result) => {
    const {destination, source} = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const newCategories = Array.from(categories);
    const [removed] = newCategories.splice(source.index, 1);
    newCategories.splice(destination.index, 0, removed);

    const newMenu = [
      ...menus.map((item, key) =>
        props.selectedElements.menu === key
          ? {
              ...item,
              categories: newCategories,
            }
          : item,
      ),
    ];

    // Updated the (menu) category order
    props.updatePlaceMenuCallback({
      menu: newMenu,
    });
    props.setSelectedElementsCallback({
      ...props.selectedElements,
      category: destination.index,
    });
  };

  /*=======================================
   *            ***Render***
   =======================================*/

  if (categories === null) {
    return null;
  }

  return (
    <>
      <DroppableArea onDragEnd={onDragEnd} droppableId={menuID.toString()}>
        {categories &&
          categories.map((category, key) => (
            <Draggable
              draggableId={key.toString()}
              index={key}
              key={key}
              /* isDragDisabled={selected !== key} */
            >
              {(provided, snapshot) => (
                <DraggableCardWrapper
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                  selected={selected === key ? true : false}>
                  <DraggableHandle
                    {...provided.dragHandleProps}
                    isHovered={'ontouchstart' in window ? true : false}
                    isDragging={snapshot.isDragging}>
                    <ScDotsHandle>
                      <TTIcon icon="dotshandle" />
                    </ScDotsHandle>
                  </DraggableHandle>
                  <ScCard key={key}>
                    {isInEditMode && key === editingIndex ? (
                      // Edit form
                      <CategoryForm
                        index={key}
                        menuID={menuID}
                        formType="EDIT"
                        setCurrentlyEditingCallback={
                          props.setCurrentlyEditingCallback
                        }
                        updatePlaceMenuCallback={props.updatePlaceMenuCallback}
                      />
                    ) : (
                      <CategoryCard
                        menus={menus}
                        menuID={menuID}
                        category={category}
                        categoryKey={key}
                        webMenuEnabled={webMenuEnabled}
                        selected={selected}
                        selectedElements={props.selectedElements}
                        setSelectedElementsCallback={
                          props.setSelectedElementsCallback
                        }
                        editCallBack={() => openEditForm(key)}
                        updatePlaceMenuCallback={props.updatePlaceMenuCallback}
                      />
                    )}
                  </ScCard>
                </DraggableCardWrapper>
              )}
            </Draggable>
          ))}
      </DroppableArea>

      {!isInEditMode && categories.length === 0 && menus[menuID] ? (
        <BlankStateMessage>
          Aggiungi la prima categoria per il menu <b>{menus[menuID].title}</b>,
          cliccando su Aggiungi categoria
        </BlankStateMessage>
      ) : null}

      {/* Add form */}
      {isInEditMode && editingIndex === categories.length + 1 ? (
        <CategoryForm
          formType="ADD"
          menuID={menuID}
          updatePlaceMenuCallback={props.updatePlaceMenuCallback}
          setCurrentlyEditingCallback={props.setCurrentlyEditingCallback}
        />
      ) : (
        <ScOpenForm>
          <TTButton icon="plus" variant="text" onClick={openAddForm}>
            Aggiungi categoria
          </TTButton>
        </ScOpenForm>
      )}
    </>
  );
};

export default CategoriesList;

////////////////////////////////////////////////////////////

const ScOpenForm = styled.div`
  padding: ${space(6)} ${space(4)};
  opacity: 0.3;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const DraggableCardWrapper = styled.div`
  display: flex;
  background-color: ${(props) =>
    props.selected === true ? '#F9F8F8' : 'inherit'};
  border-left: ${(props) =>
    props.selected === true ? '4px solid #3DA8AB' : 'none'};
`;
const ScCard = styled.div`
  flex-grow: 1;
`;

const ScDotsHandle = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`;

const BlankStateMessage = styled.div`
  font-size: 14px;
  line-height: 1.3em;
  color: #4e4e5d;
  padding: 16px;
`;
