// LIBRARIES
import React, {useState} from 'react';
import styled from 'styled-components';
import {useFormik} from 'formik';
import shortid from 'shortid';
import * as Yup from 'yup';

// CONTEXT
import StoreContext from '../../AppState/StoreContext';

//COMPONENTS
import TTInput from '../../UI/TTInput';
import FormActions from './FormActions';
import VariantList from '../../UI/VariantList';
import VariantWithOptionsReadOnly from '../../UI/VariantWithOptionsReadOnly';

// STYLES
import theme from '../../../styles/theme';
import typography from '../../../styles/typography';

import {cloneDeep} from 'lodash/fp';

////////////////////////////////////////////////////////////////

const CategoryForm = (props) => {
  // Context
  const globalStateStore = React.useContext(StoreContext);
  const {state} = globalStateStore;
  const menuobj = state.menu;
  const menus = menuobj.menu;

  let {menuID, index, formType} = props;

  const [variants, setVariants] = useState(
    cloneDeep(menus[menuID]?.categories[index]?.variants ?? []),
  );

  const category = menus[menuID] ? menus[menuID].categories[index] : null;
  const inheritedVariantsFromMenu = menus[menuID]?.variants ?? [];

  let initialValues = {
    id: shortid.generate(),
    title: '',
    hidden_to_clients: false,
    items: [],
    variants: [],
  };

  if (formType === 'EDIT') {
    initialValues = {
      id: category.id,
      title: category.title,
      items: category.items,
      variants: category?.variants ?? [],
    };
  }

  const validationSchema = Yup.object({
    title: Yup.string().required('É necessario inserire titolo'),
  });

  // This method is called from the VariantList component when
  // adding or updating the variants for the category
  const updateVariantList = (newvariants) => {
    setVariants(newvariants);
  };

  // Formik Hook
  const formik = useFormik({
    initialValues,

    validationSchema: validationSchema,

    onSubmit: () => {
      const newValues = {...formik.values, variants: variants};

      if (formType === 'EDIT') {
        //Update record
        formik.setValues({
          ...formik.values,
          variants: variants,
        });
        menus[menuID].categories[index] = newValues;
      } else {
        //Add record
        menus[menuID].categories = [...menus[menuID].categories, newValues];
      }

      // Flush the editing state object
      props.setCurrentlyEditingCallback({
        type: null,
        index: null,
      });

      // Update the db with the new values
      props.updatePlaceMenuCallback({
        menu: menus,
      });
    },
  });

  const handleCancelVariantList = () => {
    // Flush the selected elements state object
    setVariants(cloneDeep(menus[menuID]?.categories[index]?.variants ?? []));
  };

  const handleSaveVariantList = () => {
    const newValues = {...formik.values, variants: variants};

    menus[menuID].categories[index] = newValues;

    props.updatePlaceMenuCallback({
      menu: menus,
    });
  };

  /************************************************
   * Update the state for editing to close the form
   ************************************************/
  const handleCancel = () => {
    handleCancelVariantList();

    props.setCurrentlyEditingCallback({
      type: null,
      index: null,
    });
  };

  /*=======================================
   *            ***Render***
   =======================================*/
  return (
    <ScFormContainer>
      <ScForm onSubmit={formik.handleSubmit}>
        <ScFields>
          <TTInput
            required
            htmlFor="categoryTitle"
            name="title"
            id="categoryTitle"
            type="text"
            placeholder="Titolo"
            value={formik.values.title}
            onChange={(e) => formik.handleChange(e)}
          />
          {formik.touched.title && formik.errors.title && (
            <ErrorText>{formik.errors.title}</ErrorText>
          )}
        </ScFields>
        <ScFields>
          {inheritedVariantsFromMenu.length
            ? inheritedVariantsFromMenu.map((variant) => {
                if (!variant.active) {
                  return null;
                }
                return (
                  <React.Fragment key={variant.id}>
                    <Title>Varianti ereditate</Title>
                    <VariantWithOptionsReadOnly
                      inheritFrom="definite in Menu"
                      variant={variant}
                    />
                  </React.Fragment>
                );
              })
            : null}
        </ScFields>
        <ScFields>
          <VariantList
            list={variants}
            updateVariantList={updateVariantList}
            handleCancelCallback={handleCancelVariantList}
            handleSaveCallback={handleSaveVariantList}
          />
        </ScFields>
        <FormActions
          items={menus[menuID].categories}
          arrayList={formik.initialValues.items}
          formType={formType}
          index={index}
          updatePlaceMenuCallback={props.updatePlaceMenuCallback}
          handleCancelCallback={handleCancel}
          setCurrentlyEditingCallback={props.setCurrentlyEditingCallback}
        />
      </ScForm>
    </ScFormContainer>
  );
};

export default CategoryForm;

////////////////////////////////////////////////////

const ScFormContainer = styled.div`
  opacity: ${(props) => props.paused && 0.5};
  display: flex;
  align-items: flex-start;
  width: 100%;
  background-color: ${theme.colors.grays[5]};
`;

const ScFields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 8px;
  width: 100%;
`;

const ScForm = styled.form``;

const ErrorText = styled.div`
  position: relative;
  top: 4px;
  left: 4px;
  padding-bottom: 4px;
  ${typography('inputErrorMessage')};
  color: ${theme.colors.error_ink};
  font-weight: ${theme.fontWeights.normal};
`;
const Title = styled.h4`
  margin-top: 24px;
`;
