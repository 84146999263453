import theme from './theme';

const sizes = theme.fontSizes; // ["10px", "12px", "14px", "16px", "18px", "20px", "24px", "32px"];

const typography = (style) => {
  switch (style) {
    case 'canon':
      return `
      font-size: ${sizes[9]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'trafalgar':
      return `
      font-size: ${sizes[8]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'paragon':
      return `
      font-size: ${sizes[7]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'double_pica':
      return `
      font-size: ${sizes[6]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'great_primer':
      return `
      font-size: ${sizes[5]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'body_copy':
      return `
      font-size: ${sizes[4]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'pica':
      return `
      font-size: ${sizes[3]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'long_primer':
      return `
      font-size: ${sizes[2]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'brevier':
      return `
      font-size: ${sizes[1]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    case 'minion':
      return `
      font-size: ${sizes[0]};
      color:${theme.colors.ink};
      font-weight: ${theme.fontWeights.regular};
      `;

    /// These should become (styled) components
    /// These should become (styled) components
    /// These should become (styled) components
    /// These should become (styled) components
    /// These should become (styled) components
    /// These should become (styled) components
    case 'page_title':
      return `
        color:${theme.colors.ink};
      font-size: ${sizes[7]};
      font-weight: ${theme.fontWeights.medium};
      `;

    case 'page_description':
      return `
        color:${theme.colors.ink};
        font-size:  ${sizes[3]};
        font-weight: ${theme.fontWeights.normal};
        line-height: 24px;
      `;

    case 'form_label':
      return `
        color:${theme.colors.gray_2};
        font-size:  ${sizes[2]};
        font-weight: ${theme.fontWeights.normal};
        line-height: 24px;
      `;

    case 'col_title':
      return `
        color:${theme.colors.ink};
        font-size:  ${sizes[5]};
        font-weight: ${theme.fontWeights.normal};
        text-transform: lowercase;
        font-variant: small-caps;
        line-height: 24px;
      `;

    case 'table_title':
      return `
          color:${theme.colors.gray_3};
          font-size:  ${sizes[3]};
          font-weight: ${theme.fontWeights.normal};
          line-height: 24px;
        `;

    case 'product_name':
      return `
        color:${theme.colors.ink};
        font-size: ${sizes[3]};
        font-weight: ${theme.fontWeights.bold};
        line-height:24px;
      `;
    case 'product_category':
      return `
        color:${theme.colors.grays[2]};
        font-size: ${sizes[3]};
        font-weight: ${theme.fontWeights.medium};
        line-height:24px;
      `;
    case 'product_description':
      return `
          color:${theme.colors.gray_3};
          font-size: ${sizes[2]};
          font-weight: ${theme.fontWeights.normal};
        `;

    case 'nav_link':
      return `
          color:${theme.colors.ink};
          font-size: ${sizes[4]};
          font-weight: ${theme.fontWeights.medium};
          line-heigt: 24px;
          text-decoration:none;

          `;

    case 'inputErrorMessage':
      return `
      color:${theme.colors.ink};
      font-size: ${sizes[2]};
      font-weight: ${theme.fontWeights.normal};
      line-height: 16px;
      `;

    case 'h_big':
      return `
      color:${theme.colors.ink};
      font-size: ${sizes[7]};
      `;

    case 'h_medium':
      return `
      color:${theme.colors.ink};
      font-size: ${sizes[6]};
      line-heigt: 32px;
      // text-transform: uppercase;
      font-weight: ${theme.fontWeights.normal};
      `;

    case 'h_small':
      return `
      color:${theme.colors.ink};
      font-size: ${sizes[4]};
      text-transform: lowercase;
      font-variant: small-caps;
      line-heigt: 24px;
      font-weight: 400;

      `;

    case 't_xl':
      return `
      color:${theme.colors.ink};
      font-size: ${sizes[5]};
      `;

    case 't_l':
      return `
      color:${theme.colors.ink};
      font-size: ${sizes[4]};
      `;

    case 't_m':
      return `
      color:${theme.colors.ink};
      font-size: ${sizes[2]};
      font-weight: ${theme.fontWeights.normal};
      line-height: 24px;
      `;

    case 't_s':
      return `
      color:${theme.colors.ink};
      font-size: ${sizes[1]};
      `;

    default:
      console.log('Wrong style name passed to typography');
      break;
  }
};

export default typography;
