import React from 'react';
import styled from 'styled-components';

// Style
import theme from '../../../styles/theme';
import space from '../../../styles/space';

//assets
import PlaceInfo from './PlaceInfo';
import ServiceSettings from './ServiceSettings';
import TimeTable from './TimeTable';

const Settings = (props) => {
  /*=======================================
   *            ***Render***
   =======================================*/
  if (props.place) {
    return (
      <SettingsPageWrapper>
        <SectionWrapper>
          <PlaceInfo place={props.place} />
        </SectionWrapper>
        <SectionWrapper>
          <ServiceSettings place={props.place} config={props.config} />
        </SectionWrapper>
        <SectionWrapper>
          <TimeTable place={props.place} />
        </SectionWrapper>
      </SettingsPageWrapper>
    );
  } else {
    return null;
  }
};

export default Settings;

const SettingsPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  max-width: ${theme.breakpoints[2]};
  margin: 0 auto;
  padding-top: ${space(0)};
  background-color: ${theme.colors.white};
  padding-bottom: 20em;

  @media (max-width: 378px) {
    width: 90%;
  }
`;

const SectionWrapper = styled.section`
  padding: ${space(10)} ${space(6)};
`;
